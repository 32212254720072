import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../util/hooks';
import cloneDeep from 'lodash.clonedeep';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { SHIPPING_ADDRESS_TYPES } from 'util/constants';
import { Grid } from '@material-ui/core';
import StyledSelectIcon from 'components/StyledSelectIcon';
import StateSelect from '../CountrySelect/StateSelect';
import CountrySelectNew from '../CountrySelect/CountrySelectNew';
import CitySelect from 'components/CountrySelect/CitySelect';
import PortCodeSelect from 'components/CountrySelect/PortCodeSelect';

/* eslint-disable react-hooks/exhaustive-deps */
const styles = (theme) => ({
  formFields: {
    marginBottom: '20px'
  },
  formRow: {
    flex: '1 1 auto',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    '& > div': {
      minWidth: '1px',
      flexBasis: '100%'
    },
    [theme.breakpoints.up('md')]: {
      '& > div': {
        minWidth: '1px',
        flexBasis: 'calc(50% - 20px)'
      }
    }
  },
  addressRow: {
    [theme.breakpoints.up('md')]: {
      '& > div:not(:first-child)': {
        minWidth: '1px',
        flexBasis: 'calc(67% - 20px)'
      },
      '& > div:not(:last-child)': {
        minWidth: '1px',
        flexBasis: 'calc(33% - 20px)'
      }
    }
  }
});

const addressInfoFields = [
  {
    name: 'address_country',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select a country'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'address_state',
    isRequired: false,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please Select State'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'address_city',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please Select City'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'address_port_code',
    isRequired: false,
    validate: (value) => {
      // if (value === null || value === '') {
      //   return {
      //     pass: false,
      //     errorMessage: 'Please Select Port'
      //   };
      // }

      return {
        pass: true
      };
    }
  },
  {
    name: 'address_type',
    isRequired: true,
    initialValue: SHIPPING_ADDRESS_TYPES.PORT,
    validate: (value) => {
      // if (value === null || value === '') {
      //   return {
      //     pass: false,
      //     errorMessage: 'Please select a type'
      //   };
      // }

      return {
        pass: true
      };
    }
  }
];

const PortAddress = ({
  classes,
  info,
  defaultInfo,
  onUpdate,
  fieldsValidCallback,
  withErrors,
  showAllErrors,
  variant,
  transportMode,
  isCustomRequest
}) => {
  const {
    address_state,
    address_country,
    address_city,
    address_port_code,
    address_type
  } = info;

  console.log('POrt Address User Info', info, defaultInfo);
  const [filteredAddressTypes, setFilteredAddressTypes] = useState(
    SHIPPING_ADDRESS_TYPES
  );

  const fetchAddressTypes = () => {
    const typeValues = Object.values(SHIPPING_ADDRESS_TYPES);
    let filteredOpts = typeValues || [];
    if (typeValues && typeValues.length > 0) {
      if (variant === 'origin') {
        filteredOpts = typeValues.filter((c) => c.origin);
      } else if (variant === 'destination') {
        filteredOpts = typeValues.filter((c) => c.destination);
      }
    }
    return filteredOpts;
  };

  const initializeFields = (data) => {
    if (
      data.address_state ||
      data.address_country ||
      data.address_city ||
      data.address_port_code ||
      data.address_type
    ) {
      const updatedFields = cloneDeep(addressInfoFields);
      updatedFields[0].initialValue = data.address_country;
      updatedFields[1].initialValue = data.address_state;
      updatedFields[2].initialValue = data.address_city;
      updatedFields[3].initialValue = data.address_port_code;
      updatedFields[4].initialValue = data.address_type;

      return updatedFields;
    }

    return addressInfoFields;
  };

  const updatedInfoFields = useMemo(
    () =>
      initializeFields({
        address_state,
        address_country,
        address_city,
        address_port_code,
        address_type
      }),
    [
      address_state,
      address_country,
      address_city,
      address_port_code,
      address_type
    ]
  );

  const [infoState, infoDispatch, infoFieldsAreValid, infoErrors] = useForm(
    updatedInfoFields,
    {
      debounceValidation: 200
    }
  );

  const [showErrors, setShowErrors] = useState(() =>
    updatedInfoFields
      .map((field) => field.name)
      .reduce((stateMap, key) => {
        const tempStateMap = stateMap;
        tempStateMap[key] = false;
        return tempStateMap;
      }, {})
  );

  useEffect(() => {
    if (showAllErrors) {
      setShowErrors(
        updatedInfoFields
          .map((field) => field.name)
          .reduce((stateMap, key) => {
            const tempStateMap = stateMap;
            tempStateMap[key] = true;
            return tempStateMap;
          }, {})
      );
    }
  }, [showAllErrors, updatedInfoFields]);

  useEffect(() => {
    console.log('Value updating infoState PortAdress', infoState);
    onUpdate(infoState);
    fieldsValidCallback(infoFieldsAreValid);
  }, [infoState, infoFieldsAreValid]);

  const updateField = (e) => {
    const { name, value } = e.target;

    setShowErrors({ ...showErrors, [name]: false });

    infoDispatch({
      type: 'UPDATE_FIELD',
      name,
      value
    });
  };

  const handleAddressType = (event) => {
    console.log('Value handleAddressType', event.target.value);
    const selectedType = event.target.value || '';
    const typeObj = Object.values(SHIPPING_ADDRESS_TYPES).find(
      (opt) => opt.value === selectedType
    );
    console.log('selectedType', typeObj);

    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_type',
      value: typeObj
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_city',
      value: ''
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_port_code',
      value: ''
    });
  };

  const handleCountry = (event, country) => {
    // console.log('Value handleCountry', country);
    const selectedCountries = country || '';
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_country',
      value: selectedCountries
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_state',
      value: ''
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_city',
      value: ''
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_port_code',
      value: ''
    });
  };

  const handleState = (event, state) => {
    // console.log('Value handleCountry', state);
    const selectedState = state || '';
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_state',
      value: selectedState
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_city',
      value: ''
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_port_code',
      value: ''
    });
  };

  const handleCity = (event, city) => {
    console.log('Value handleCountry', city);
    const selectedCity = city || '';
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_city',
      value: selectedCity
    });
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_port_code',
      value: ''
    });
  };

  const handlePortCode = (event, portCode) => {
    // console.log('Value handleCountry', portCode);
    const selectedPortCode = portCode || '';
    infoDispatch({
      type: 'UPDATE_FIELD',
      name: 'address_port_code',
      value: selectedPortCode
    });
  };

  useEffect(() => {
    console.log('One tiume port address', infoState, defaultInfo);
    //Set default Addres Type on load
    if (!infoState.address_country) {
      console.log('No address country');
      const selectedCountry = defaultInfo.address_country

      // setFilteredLocations(LOCATIONS_BY_COUNTRY[selectedCountry.value]);
      infoDispatch({
        type: 'UPDATE_FIELD',
        name: 'address_country',
        value: selectedCountry
      });

    }
    if (!infoState.address_type) {
      infoDispatch({
        type: 'UPDATE_FIELD',
        name: 'address_type',
        value: defaultInfo.address_type
      });
    }
  }, []);

  return (
    <div className="">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledSelectIcon
            id="addressType"
            name="address_type"
            label="Address Type"
            margin="small"
            value={
              infoState.address_type
                ? infoState.address_type.value
                : defaultInfo && defaultInfo.address_type
                  ? defaultInfo.address_type.value
                  : fetchAddressTypes()[0].value
            }
            options={fetchAddressTypes()}
            error={
              showErrors.address_type && infoErrors.address_type ? true : false
            }
            onChange={handleAddressType}
            onBlur={() => {
              setShowErrors({ ...showErrors, address_type: true });
            }}
            required={true}
            helperText={infoErrors.address_type}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CountrySelectNew
            name="address_country"
            value={infoState.address_country}
            transportMode={transportMode}
            useDefault
            error={infoErrors.address_country}
            showError={
              withErrors &&
                showErrors.address_country &&
                infoErrors.address_country
                ? true
                : false
            }
            isClearable={false}
            isMulti={false}
            onChange={handleCountry}
            onBlur={() => {
              setShowErrors({ ...showErrors, address_country: true });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StateSelect
            name="address_state"
            required={true}
            transportMode={transportMode}
            country={infoState?.address_country?.code}
            value={infoState?.address_state}
            error={infoErrors.address_state}
            disabled={!infoState.address_country?.code}
            showError={
              withErrors && showErrors.address_state && infoErrors.address_state
                ? true
                : false
            }
            handleChange={handleState}
            onBlur={() => {
              setShowErrors({ ...showErrors, address_state: true });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {infoState.address_type &&
            infoState.address_type.value !==
            SHIPPING_ADDRESS_TYPES.PORT.value && (
              <CitySelect
                name="address_city"
                value={infoState.address_city}
                state={infoState?.address_state?.code}
                error={infoErrors.address_city}
                showError={
                  withErrors &&
                    showErrors.address_city &&
                    infoErrors.address_city
                    ? true
                    : false
                }
                handleChange={handleCity}
                onBlur={() => {
                  setShowErrors({ ...showErrors, address_city: true });
                }}
              />
            )}
          {(!infoState.address_type ||
            infoState.address_type.value ===
            SHIPPING_ADDRESS_TYPES.PORT.value) && (
              <PortCodeSelect
                name="address_port_code"
                required={isCustomRequest}
                transportMode={transportMode}
                city={infoState?.address_state?.code}
                value={infoState?.address_port_code}
                error={infoErrors.address_port_code}
                disabled={!infoState.address_country?.code}
                showError={
                  withErrors &&
                    showErrors.address_port_code &&
                    infoErrors.address_port_code
                    ? true
                    : false
                }
                handleChange={handlePortCode}
                onBlur={() => {
                  setShowErrors({ ...showErrors, address_port_code: true });
                }}
              />
            )}
        </Grid>
      </Grid>
    </div>
  );
};

PortAddress.defaultProps = {
  info: {},
  withErrors: true,
  onUpdate: () => { },
  fieldsValidCallback: () => { },
  variant: 'all',
  isCustomRequest: false
};

PortAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onUpdate: PropTypes.func.isRequired,
  showAllErrors: PropTypes.bool,
  withErrors: PropTypes.bool,
  fieldsValidCallback: PropTypes.func,
  variant: PropTypes.oneOf(['origin', 'destination', 'all']),
  isCustomRequest: PropTypes.bool
};

export default compose(withStyles(styles))(PortAddress);
