import {
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CustomPasswordCheckList from 'components/PasswordCheckList';
import PhoneNumberInput from 'components/PhoneNumberInput';
import StyledInput from 'components/StyledInput';
import StyledSelect from 'components/StyledSelect';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AdminTeam from 'service/AdminTeam';
import { notify } from 'sites/shipper/components/Toast/toast';
import { DOBErrorMsg, isValidDateOfBirth, maxDateOfBirth } from 'util/Utils';
import Style from './TeamMemberRegistration.module.css';

export function TeamMemberRegistrationForm() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [DOBError, setDOBError] = useState('');

  const [isValidPassword, setIsValidPassword] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState();

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [data, setData] = useState({
    token: token,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    phoneNumberCode: '',
    password: '',
    dateOfBirth: null,
    jobTitle: '',
    gender: ''
    // personal_suite: '',
    // personal_street: '',
    // personal_city: '',
    // personal_state: '',
    // personal_country: '',
    // personal_postal_code: ''
  });
  const handleValue = (e) => {
    const { name, value } = e.target;
    if (name === 'dateOfBirth') {
      setDOBError('');
      if (!isValidDateOfBirth(value)) {
        setDOBError(DOBErrorMsg);
      }
    }

    setData({
      ...data,
      [name]: value
    });
  };

  useEffect(() => {
    setError('');
    setPasswordStrengthError('');
  }, [data]);

  const handleSubmit = () => {
    // console.log(data)
    if (
      data.dateOfBirth === '' ||
      data.firstName === '' ||
      data.lastName === '' ||
      data.password === '' ||
      data.jobTitle === '' ||
      data.gender === '' ||
      // data.personal_city === "" ||
      // data.personal_country === "" ||
      // data.personal_postal_code === "" ||
      // data.personal_state === "" ||
      // data.personal_street === "" ||
      // data.personal_suite === "" ||
      data.phoneNumber === '' ||
      data.phoneNumberCode === ''
    ) {
      setError('All Fields are required');
      return;
    }
    setLoading(true);
    AdminTeam.registerTeam(data).then((response) => {
      if (response.error) {
        setError(response.error);
      } else {
        // window.alert(response.message)
        notify(dispatch, 'success', response.message);
        history.push('/login');
      }
      setLoading(false);
    });
  };

  return (
    <>
      <div>
        <div className={Style.form_container}>
          <div className={Style.form}>
            <span className={Style.form_legend}>Team Member Register Form</span>

            <div className={Style.grid_container}>
              <StyledInput
                margin="0"
                label="First Name*"
                id="first-name"
                name="firstName"
                value={data.firstName}
                onChange={handleValue}
              />
              <StyledInput
                margin="0"
                label="Last Name*"
                id="last-name"
                name="lastName"
                value={data.lastName}
                onChange={handleValue}
              />

              <PhoneNumberInput
                country={data.phoneNumberCode}
                number={data.phoneNumber}
                handleCountry={(val) =>
                  setData({ ...data, phoneNumberCode: val })
                }
                handleNumber={(val) => setData({ ...data, phoneNumber: val })}
              />

              <StyledInput
                margin="0"
                label="Job Title"
                id="job-title"
                name="jobTitle"
                value={data.jobTitle}
                onChange={handleValue}
              />

              <StyledInput
                margin="0"
                type="date"
                label="Date of Birth*"
                id="d-o-b"
                name="dateOfBirth"
                value={data.dateOfBirth || ''}
                onChange={handleValue}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                // InputProps={{
                //   placeholder: data.dateOfBirth ? undefined : 'Date of Birth',
                // }}
                error={DOBError}
                maxDate={maxDateOfBirth()}
              />

              <StyledSelect
                label="Select Gender*"
                name="gender"
                margin="0"
                value={data.gender}
                onChange={(e) => setData({ ...data, gender: e.target.value })}
                size="small"
                options={[
                  { value: 'Male', label: 'Male' },
                  { value: 'Female', label: 'Female' },
                  { value: 'Other', label: 'Other' }
                ]}
              />
            </div>

            {/* <span className={Style.sub_legend}>Personal Address</span> */}

            {/* <div className={Style.grid_container}>
                    <StyledInput margin="0" label='Suite' id='personal-suite' name="personal_suite" value={data.personal_suite} onChange={handleValue} />
                    <StyledInput margin="0" id='personal-street' label='Street*' name="personal_street" value={data.personal_street} onChange={handleValue} />
                    <CountrySelectNew
                      name="country"
                      value={data.personal_country}
                      useDefault
                      showError={false}
                      isClearable={false}
                      isMulti={false}
                      onChange={handleCountry}
                    />
                    <StateSelect
                      name="state"
                      country={data.personal_country._id}
                      value={data.personal_state}
                      showError={false}
                      handleChange={handleState}
                    />
                    <CitySelect
                      name="personal_city"
                      value={data.personal_city}
                      state={data?.personal_state?._id}
                      showError={false}
                      handleChange={handleCity}
                    />
                   
                    <StyledInput margin="0" label='Postal Code' id='personal_postal_code' name="personal_postal_code" value={data.personal_postal_code} onChange={handleValue} />
                  </div>*/}

            <FormControl variant="outlined" size="small">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                value={data.password}
                onChange={handleValue}
                autoComplete="password"
                // onBlur={checkPasswordStrength}
                className={`${Style.input}`}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            {/* {passwordStrengthError && <span className='text-danger font-size-xs'>{passwordStrengthError}</span>} */}
            {data.password && (
              <div className="mt-1">
                <CustomPasswordCheckList
                  password={data.password}
                  setIsValidPassword={setIsValidPassword}
                />
              </div>
            )}
            {error && <span className="text-danger font-size-xs">{error}</span>}
            <Button
              className={`btn-primary`}
              onClick={handleSubmit}
              disabled={
                data.dateOfBirth === '' ||
                data.firstName === '' ||
                data.lastName === '' ||
                data.password === '' ||
                data.jobTitle === '' ||
                data.gender === '' ||
                data.phoneNumber === '' ||
                data.phoneNumberCode === '' ||
                // passwordStrengthError ||
                !isValidPassword ||
                DOBError
              }>
              Register
            </Button>
          </div>

          <Divider />

          <div className={Style.caption}>
            By registering, you agree to the processing of your personal data by
            Salesforce as described in the Privacy Statement.
          </div>
        </div>
      </div>
    </>
  );
}
