export const departments = [
  {
    label: 'Sales',
    value: 'sales'
  },
  {
    label: 'Operations',
    value: 'operations'
  },
  {
    label: 'Engineering',
    value: 'engineering'
  },
  {
    label: 'Logistics',
    value: 'logistics'
  },
  {
    label: 'Marketing',
    value: 'marketing'
  },
  {
    label: 'Human Resources',
    value: 'human_resources'
  },
  {
    label: 'Customer Support',
    value: 'customer_support'
  },
  {
    label: 'Finance',
    value: 'finance'
  },
  {
    label: 'Legal',
    value: 'legal'
  },
  {
    label: 'Product Development',
    value: 'product_development'
  },
  {
    label: 'Quality Assurance',
    value: 'quality_assurance'
  },
  {
    label: 'Information Technology',
    value: 'it'
  },
  {
    label: 'Research and Development',
    value: 'research_and_development'
  },
  {
    label: 'Procurement',
    value: 'procurement'
  },
  {
    label: 'Training',
    value: 'training'
  }
];
