const { TRIGGER_SHIPPER_REFRESH } = require('./action');

const initialState = {
  shipperRefreshTimeStamp: null
};

const refreshReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_SHIPPER_REFRESH:
      return {
        ...state,
        shipperRefreshTimeStamp: Date.now()
      };
    default:
      return state;
  }
};

export default refreshReducer;
