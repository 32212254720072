import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Box,
  Typography,
  Popover,
  Menu,
  Button,
  List,
  ListItem,
  Divider
} from '@material-ui/core';

import CalendarTodayTwoToneIcon from '@material-ui/icons/CalendarTodayTwoTone';
import CollectionsTwoToneIcon from '@material-ui/icons/CollectionsTwoTone';
import DnsTwoToneIcon from '@material-ui/icons/DnsTwoTone';
import HomeWorkTwoToneIcon from '@material-ui/icons/HomeWorkTwoTone';
import { withStyles } from '@material-ui/styles';
import { handlePreview } from 'util/OpenPreviewWindow/OpenPreviewWindow';
import { userPermission } from '../../../../../../util/adminPermissionRoles';
import { useSelector } from 'react-redux';
const styles = () => ({});

const HeaderMenu = ({ classes }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const auth = useSelector((state) => state.session.auth);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mega-menu-popover' : undefined;

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  return (
    <>
      <div className="app-header-menu">
        {(userPermission.shipment_request.read.includes(
          auth.accountCmpyLinkType
        ) ||
          userPermission.rate_sheet.read.includes(auth.accountCmpyLinkType) ||
          userPermission.service_management.read.includes(
            auth.accountCmpyLinkType
          ) ||
          userPermission.source_management.read.includes(
            auth.accountCmpyLinkType
          ) ||
          userPermission.tag_management.read.includes(
            auth.accountCmpyLinkType
          ) ||
          userPermission.carrier.read.includes(auth.accountCmpyLinkType)) && (
          <Button
            onClick={handleClickMenu}
            size="small"
            className="btn-transition-none btn-neutral-dark mr-3">
            Tools
          </Button>
        )}
        <Menu
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          classes={{ list: 'p-0' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}>
          <Box
            style={{ width: '450px' }}
            className="overflow-hidden border-0 bg-midnight-bloom p-3 dropdown-mega-menu-md">
            <div className="p-2">
              <div className="font-weight-bold font-size-xl mb-1 text-white">
                Admin Tools
              </div>
            </div>
            <div className="d-flex flex-wrap">
              {userPermission.shipment_request.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/shipment-request');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div>
                      {/* <DnsTwoToneIcon className="h1 d-block my-2 text-warning" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'building']}
                        className="h1 d-block my-2 text-warning"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Shipment Request
                      </div>
                    </div>
                  </Button>
                </div>
              )}

              {userPermission.carrier.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    onClick={() => {
                      handlePreview('/carriers');
                      handleCloseMenu();
                    }}
                    style={{ width: '100%', textAlign: 'left' }}
                    className="d-block bg-white p-3 text-primary">
                    <div>
                      {/* <HomeWorkTwoToneIcon className="h1 d-block my-2 text-first" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'network-wired']}
                        className="h1 d-block my-2 network-wired"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Carriers
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {userPermission.rate_sheet.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/rate-sheet');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div style={{ width: '100%' }}>
                      {/* <CollectionsTwoToneIcon className="h1 d-block my-2 text-danger" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'globe-africa']}
                        className="h1 d-block my-2 text-first"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Rate Sheet
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {userPermission.service_management.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/service-management');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div>
                      {/* <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-success" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'puzzle-piece']}
                        className="h1 d-block my-2 text-success"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Service Management
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {userPermission.tag_management.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/tag-managment');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div>
                      {/* <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-success" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'tags']}
                        className="h1 d-block my-2 text-success"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Tag Management
                      </div>
                    </div>
                  </Button>
                </div>
              )}
              {userPermission.source_management.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/source-managment');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div>
                      {/* <CalendarTodayTwoToneIcon className="h1 d-block my-2 text-success" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'clipboard-list']}
                        className="h1 d-block my-2 text-success"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Source Management
                      </div>
                    </div>
                  </Button>
                </div>
              )}

              {userPermission.rate_sheet.read.includes(
                auth.accountCmpyLinkType
              ) && (
                <div className="w-50 p-2">
                  <Button
                    style={{ width: '100%', textAlign: 'left' }}
                    onClick={() => {
                      handlePreview('/referral-source-management');
                      handleCloseMenu();
                    }}
                    className="d-block bg-white p-3 text-primary">
                    <div style={{ width: '100%' }}>
                      {/* <CollectionsTwoToneIcon className="h1 d-block my-2 text-danger" /> */}
                      <FontAwesomeIcon
                        icon={['fas', 'globe-africa']}
                        className="h1 d-block my-2 text-first"
                        style={{ width: '28px', height: '28px' }}
                      />
                      <div className="text-black font-size-md font-weight-bold">
                        Referral Source Mgmt
                      </div>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </Box>
        </Menu>
      </div>
    </>
  );
};

export default withStyles(styles)(HeaderMenu);
