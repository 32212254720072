import Style from './RateSheet.module.css';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import TableRow from './table_row/TableRow';
import { Button, Divider, MenuItem, Tooltip } from '@material-ui/core';
import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RateSheetService from 'service/RateSheetService';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CountryFlag from 'components/CountryFlag';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import RightDrawer from 'sites/shipper/components/layout/RightDrawer';
import ShipmentRouteForm from '../ShipmentRouteForm/ShipmentRouteForm';
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import CachedIcon from '@mui/icons-material/Cached';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import { useHistory } from 'react-router-dom';
import ConfirmDialog from '../../../components/atomic/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';
import {
  CARGO_CATEGORIES,
  TRANSPORT_MODES,
  TRANSPORT_MODES_LIST
} from 'util/constants';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveRouteInfo, setFilterDataInRedux } from 'actions';
import RouteFilter from '../RouteFilter/RouteFilter';
import ModalSimple from 'components/ModalSimple';
import FullContainerLoad from '../RateSheetInfo/LoadTypes/FullContainerLoad';
import Blank from '../blank/Blank';
import ROROContainer from '../RateSheetInfo/LoadTypes/ROROContainer';
import BulkContainer from '../RateSheetInfo/LoadTypes/BulkContainer';
import RateSheetInfoRouteDetails from '../RateSheetInfo/RateSheetInfoRouteDetails';
/* eslint-disable */

const RateSheet = ({
  isMakeOffer = false,
  loadType = null,
  transportMode = '',
  makeOfferCallBack = () => {}
}) => {
  console.log('Ratesheet LoadType', loadType);
  const history = useHistory();
  const dispatch = useDispatch();
  const [rateSheets, setRateSheets] = useState([]);
  const auth = useSelector((state) => state.session.auth);
  const [run, setRun] = useState(false);
  const [filters, setFilters] = useState({
    transport_mode: transportMode,
    origin: {
      country: '',
      city: '',
      state: '',
      port: ''
    },
    destination: {
      country: '',
      city: '',
      state: '',
      port: ''
    },
    keyword: ''
  });

  const [loading, setLoading] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const toggleRightDrawer = (bool) => {
    setOpenRightDrawer(bool);
  };

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const toggleFilterDrawer = (bool) => {
    setOpenFilterDrawer(bool);
  };

  const FilterDataFromRedux = useSelector(
    (state) => state.filterDataRedux.data
  );

  const searchParams = new URLSearchParams(location.search);

  const [rateSheetCount, setRateSheetCount] = useState(0);

  const pageValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(pageValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  // useEffect(() => {
  //   if (loading) return;

  //   const element = document.getElementById('top');
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }, [check]);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/rate-sheet?page=${value}`);
  }

  const inits = async () => {
    setLoading(true);
    let data_to_send = {};

    if (FilterDataFromRedux && FilterDataFromRedux.page === 'rateSheet') {
      data_to_send = FilterDataFromRedux;
    } else {
      data_to_send = filters;
    }
    await RateSheetService.fetchRateSheet(
      auth.accountId,
      data_to_send,
      auth.token,
      page
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
        setRateSheets([]);
      } else {
        setRateSheets(res?.data);
        setRateSheetCount(res?.rateSheetRoutesCount);
      }
      setLoading(false);
      toggleFilterDrawer(false);
    });
  };

  const handleFilter = () => {
    const tempdata = {
      ...filters,
      page: 'rateSheet'
    };
    dispatch(setFilterDataInRedux(tempdata));
    toggleFilterDrawer(false);
  };

  useEffect(() => {
    // on mount fetch and set redux filters
    if (FilterDataFromRedux && FilterDataFromRedux.page === 'rateSheet') {
      setFilters(FilterDataFromRedux);
    }

    // reset redux filters redux on unmount
    return () => {
      dispatch(setFilterDataInRedux(null));
    };
  }, []);

  useEffect(() => {
    inits();
  }, [auth, run, FilterDataFromRedux, page]);

  const TableHeading = [
    'Mode',
    'OCC',
    'Origin State',
    'Origin City',
    'Origin Port',
    'DCC',
    'Destination State',
    'Destination City',
    'Destination Port',
    'Action'
  ];

  const Keys = [
    'transportMode',
    'origin_country',
    'origin_state',
    'origin_city',
    'origin_port',
    'destination_country',
    'destination_state',
    'destination_city',
    'destination_port',
    '_id'
  ];
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const handleDelete = () => {
    if (!moreOptionClicked._id) {
      return;
    }
    const data_to_send = {
      routeId: moreOptionClicked._id,
      origin: moreOptionClicked?.origin,
      destination: moreOptionClicked?.destination,
      transportMode: moreOptionClicked?.transportMode
    };
    RateSheetService.deleteRateSheet(data_to_send, auth.token).then((data) => {
      if (data.message) {
        notify(dispatch, 'success', data.message);
      } else {
        notify(dispatch, 'error', data.error);
      }
      setRun(!run);
    });
  };

  const [openRouteRateModal, setOpenRouteRateModal] = useState(false);

  const handleViewRouteRates = (routeItem) => {
    console.log('handleViewRouteRates', routeItem);
    setOpenRouteRateModal(true);
  };

  const TableData = rateSheets?.map((item) =>
    Keys.map((Key) => {
      if (Key === '_id') {
        return (
          <>
            {!isMakeOffer && (
              <div className="position-relative">
                <center>
                  <Tooltip title="More" arrow>
                    <Button
                      variant="text"
                      className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
                      onClick={(e) => {
                        setOpenMoreOptions(e.currentTarget);
                        setMoreOptionClicked(item);
                      }}>
                      <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
                    </Button>
                  </Tooltip>
                </center>
                {moreOptionClicked?._id === item?._id && (
                  <SmallPopupWrapper
                    open={openMoreOptions}
                    onClose={() => setOpenMoreOptions(null)}>
                    <MenuItem
                      onClick={() => {
                        dispatch(saveRouteInfo(moreOptionClicked));
                        history.push(`/rate-sheet/${moreOptionClicked._id}`);
                      }}>
                      View Rates
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleRightDrawer(true);
                        setOpenMoreOptions(null);
                      }}>
                      Edit Route
                    </MenuItem>
                    <MenuItem onClick={() => setConfirmDialogOpen(true)}>
                      Delete Route
                    </MenuItem>
                  </SmallPopupWrapper>
                )}
              </div>
            )}
            {isMakeOffer && (
              <div className="position-relative">
                <center>
                  <Tooltip title="View Route Rates" arrow>
                    <Button
                      variant="text"
                      className={`btn-neutral-primary py-1 px-2`}
                      onClick={() => {
                        dispatch(saveRouteInfo(item));
                        setMoreOptionClicked(item);
                        handleViewRouteRates(item);
                      }}>
                      View
                    </Button>
                  </Tooltip>
                </center>
              </div>
            )}
          </>
        );
      }
      if (Key === 'origin_country') {
        return <CountryFlag countryCode={item?.origin?.country?.code} />;
      }
      if (Key === 'origin_port') {
        return `${item?.origin?.port?.code}`;
      }
      if (Key === 'origin_city') {
        return `${item?.origin?.city?.name}`;
      }
      if (Key === 'origin_state') {
        return `${item?.origin?.state?.name}`;
      }
      if (Key === 'destination_country') {
        return <CountryFlag countryCode={item?.destination?.country?.code} />;
      }
      if (Key === 'destination_port') {
        return `${item?.destination?.port?.code}`;
      }
      if (Key === 'destination_city') {
        return `${item?.destination?.city?.name}`;
      }
      if (Key === 'destination_state') {
        return `${item?.destination?.state?.name}`;
      }
      if (Key === 'transportMode') {
        const icon = TRANSPORT_MODES_LIST.filter(
          (x) => x.value === item?.transportMode
        )[0]?.icon;
        return (
          <FontAwesomeIcon
            className="mb-1 mt-1  d-24"
            icon={['fas', `${icon}`]}
          />
        );
      } else {
        return item[Key] || '';
      }
    })
  );

  const handleSearchByKeyord = (value) => {
    const search_item = value;

    setFilters({
      ...filters,
      keyword: search_item
    });
    const tempdata = {
      ...filters,
      keyword: search_item,
      page: 'rateSheet'
    };
    dispatch(setFilterDataInRedux(tempdata));
    handlePageChange('', 1);
  };

  return (
    <>
      {/* {loading && <LoaderComponent loading={loading} />} */}

      <TableWithSearchBar
        loading={loading}
        animatedSearchbar={{
          show: true,
          value: filters.keyword,
          onChange: handleSearchByKeyord,
          setLoading: setLoading
        }}
        // tableMainHeading="Routes"
        TableHeading={TableHeading}
        TableBody={TableData}
        TableGrid={'0.5fr 0.5fr repeat(3, 1fr) 0.5fr repeat(3, 1fr) 0.5fr'}
        actionButtons={[
          <Button
            variant="text"
            onClick={() => setRun(!run)}
            className={`${Style.icon_btn} btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <CachedIcon className="w-50" />
          </Button>,
          <Button
            onClick={() => {
              toggleFilterDrawer(true);
              setMoreOptionClicked(null);
            }}
            variant="text"
            className={`${Style.icon_btn} btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
            <FilterListTwoToneIcon className="w-50" />
          </Button>,
          ...(isMakeOffer
            ? []
            : [
                <Button
                  className="btn-primary"
                  onClick={() => {
                    toggleRightDrawer(true);
                    setMoreOptionClicked(null);
                  }}>
                  Create Route
                </Button>
              ])
        ]}
        pagination={{
          count: Math.ceil(rateSheetCount / itemsPerPage),
          page: page,
          onChange: handlePageChange
        }}
      />

      {/* Add route form */}
      <RightDrawer
        onClose={() => toggleRightDrawer(false)}
        open={openRightDrawer}>
        <ShipmentRouteForm
          info={moreOptionClicked}
          run={run}
          setRun={setRun}
          toggleRightDrawer={toggleRightDrawer}
        />
      </RightDrawer>

      {/* Route Filter */}
      <RightDrawer
        onClose={() => toggleFilterDrawer(false)}
        open={openFilterDrawer}>
        <RouteFilter
          filters={filters}
          setFilters={setFilters}
          handleSubmit={handleFilter}
          isMakeOffer={isMakeOffer}
        />
      </RightDrawer>

      <ConfirmDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        confirmDialogFunction={handleDelete}
        question={'Are you sure you want to delete this route?'}
      />

      {moreOptionClicked && (
        <ModalSimple
          modalTitle={'View All Route Rates'}
          open={openRouteRateModal}
          maxWidth={'xxl'}
          fullWidth={true}
          handleClose={() => setOpenRouteRateModal(false)}
          actionButtonText={'Confirm'}>
          <div className={Style.modalContentContainer}>
            {moreOptionClicked.transportMode === TRANSPORT_MODES.SEA.value && (
              <>
                <RateSheetInfoRouteDetails routeInfo={moreOptionClicked} />
                {loadType === CARGO_CATEGORIES.FCL.value && (
                  <FullContainerLoad
                    route_id={moreOptionClicked._id}
                    run={run}
                    setRun={setRun}
                    transportMode={moreOptionClicked?.transportMode}
                    isMakeOffer={isMakeOffer}
                    makeOfferCallBack={(data) => {
                      setOpenRouteRateModal(false);
                      makeOfferCallBack(data);
                    }}
                  />
                )}

                {loadType === CARGO_CATEGORIES.LCL.value && <Blank />}
                {loadType === CARGO_CATEGORIES.RORO.value && (
                  <ROROContainer
                    route_id={moreOptionClicked._id}
                    run={run}
                    setRun={setRun}
                    transportMode={moreOptionClicked?.transportMode}
                    isMakeOffer={isMakeOffer}
                    makeOfferCallBack={(data) => {
                      setOpenRouteRateModal(false);
                      makeOfferCallBack(data);
                    }}
                  />
                )}
                {loadType === CARGO_CATEGORIES.BULK.value && <Blank />}
              </>
            )}

            {moreOptionClicked.transportMode === TRANSPORT_MODES.AIR.value && (
              <>
                {loadType === CARGO_CATEGORIES.PKGS.value && (
                  <BulkContainer
                    route_id={moreOptionClicked._id}
                    loadType={CARGO_CATEGORIES.PKGS.label}
                    run={run}
                    setRun={setRun}
                    transportMode={moreOptionClicked?.transportMode}
                    isMakeOffer={isMakeOffer}
                    makeOfferCallBack={(data) => {
                      setOpenRouteRateModal(false);
                      makeOfferCallBack(data);
                    }}
                  />
                )}
              </>
            )}
          </div>
        </ModalSimple>
      )}
    </>
  );
};
/* eslint-enable */

export default RateSheet;
