import { HttpMethods, HttpService } from './index';

export default class ShipperService {
  static createShipper(data, token) {
    const url = 'admin/shipper/create';
    return HttpService.request(HttpMethods.POST, url, data, {}, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static resendShipperTempPassword(data, token) {
    const url = 'admin/shipper/resend-temporary-password';
    return HttpService.request(HttpMethods.POST, url, data, {}, token)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  }

  static verifyShipperEmail(data) {
    const url = 'user/verify-shipper-registration';
    return HttpService.request(HttpMethods.POST, url, data)
      .then((res) => {
        console.log('createUser SRVICE resp', res);
        return res;
      })
      .catch((err) => console.error(err));
  }
}
