import React, { useEffect, useState } from 'react';
import Style from './registration.module.css';
import TopText from '../../Login/LoginForm/TopText/TopText';
import { TeamMemberRegistrationForm } from './teamMemberRegistrationForm';
import { TeamMemberRegistrationExipred } from './teamMemberRegistrationExpired';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import AdminTeam from 'service/AdminTeam';
import { useParams } from 'react-router-dom';

export default function TeamMemberRegistartion() {
  const { token } = useParams();
  const [expiredToken, setExpiredToken] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    AdminTeam.isTokenExpired(token).then((result) => {
      if (!result.success) {
        setExpiredToken(true);
      } else {
        setExpiredToken(false);
      }
      setLoading(false);
    });
  }, [token]);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      {expiredToken ? (
        <>
          <TeamMemberRegistrationExipred />
        </>
      ) : (
        <>
          <div className={Style.container}>
            <div className={Style.left_container}>
              <div
                className="d-flex flex-column w-60"
                style={{ gap: '1.5rem' }}>
                <TopText dark={true} />
                <TeamMemberRegistrationForm />
              </div>
            </div>
            <div className={Style.right_container}>
              <span className={Style.text_heading}>
                Revolutionizing Logistics Connectivity
              </span>
              <span className={Style.text_desc}>
                Revolutionizing Freight Logistics for a Digital Future
              </span>

              <ul className={Style.text_list}>
                <li>
                  A comprehensive solution for both forwarders and shippers
                </li>
                <li>
                  Stay informed with accurate and up-to-date shipment tracking
                </li>
                <li>
                  Access a range of tools and features to facilitate smooth
                  operations
                </li>
                <li>
                  Enable seamless communication between forwarders and shippers
                </li>
              </ul>

              <span className={Style.bottom_text_desc}>
                Unleashing the power of global collaboration, our platform
                brings together freight forwarders worldwide, offering them
                cutting-edge web-based tools and resources to supercharge
                business growth and foster unrivaled success.
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}
