import React, { useEffect, useState } from 'react';
import Style from './AnimatedSearchbar.module.css';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import clsx from 'clsx';

const AnimatedSearchbar = ({
  value: initialvalue = '',
  children,
  onChange,
  setLoading,
  placeholder = 'Search...'
}) => {
  const [searchOpen, setSearchOpen] = useState(false);

  const openSearch = () => setSearchOpen(true);
  const closeSearch = () => setSearchOpen(false);

  const [run, setRun] = useState(false);
  const [value, setValue] = useState(initialvalue);

  useEffect(() => {
    setValue(initialvalue);
  }, [initialvalue]);

  useEffect(() => {
    if (run) {
      if (setLoading) {
        setLoading(true);
      }
      const timeOut = setTimeout(() => {
        if (onChange) {
          console.log(value);
          onChange(value);
        }
      }, 1500);
      return () => clearTimeout(timeOut);
    }
  }, [value, run]);

  return (
    <div className={Style.search_bar}>
      <div className="w-100">
        <div
          className={clsx(
            'search-wrapper search-wrapper--alternate search-wrapper--grow',
            {
              'is-active': searchOpen
            }
          )}>
          <TextField
            variant="outlined"
            value={value}
            placeholder={placeholder}
            size="small"
            id="input-with-icon-textfield22-2"
            onChange={(e) => {
              setValue(e?.target?.value);
              setRun(true);
            }}
            onFocus={openSearch}
            onBlur={closeSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div
        className="d-flex c-gap-half justify-content-end"
        style={{ width: '300px' }}>
        {children}
      </div>
    </div>
  );
};

export default AnimatedSearchbar;
