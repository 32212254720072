import ModalSimple from 'components/ModalSimple';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeadsManagementModalForm } from './leads-mgmt-modal-form';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';
import LeadService from 'service/LeadService';
import { notify } from 'sites/shipper/components/Toast/toast';

export function LeadsManagementModal({ open, handleModal, handleRun }) {
  const [data, setData] = useState({
    email: ''
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data_to_send = {
      email: data.email
    };
    const result = await LeadService.addUser(data_to_send, auth.token);
    if (result.error) {
      setError(result.error);
    } else {
      notify(dispatch, 'success', result.message);
      handleRun();
      handleModal();
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError('');
    setData({
      ...data,
      [name]: value
    });
    if (name === 'email') {
      setIsEmailValid(validateEmail(value));
    }
  };

  return (
    <>
      <ModalSimple
        modalTitle={'Add user to list'}
        open={open}
        action={() => isEmailValid && setConfirmSubmit(true)} // Only open the confirm dialog if email is valid
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={handleModal}
        actionButtonText={'Add'}
        actionButtonDisabled={!isEmailValid} // Disable the button if email is invalid
      >
        <LeadsManagementModalForm data={data} handleChange={handleChange} />
        {error && (
          <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
        )}
        {!isEmailValid && data.email && (
          <span style={{ color: 'red', fontSize: '12px' }}>
            Please enter a valid email address.
          </span>
        )}
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={handleSubmit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure you want to add user to the notification list`}
      />
    </>
  );
}
