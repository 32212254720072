import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Button, Slide } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalAlert = ({ title, description, open, onClose }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={() => onClose()}
      classes={{ paper: 'shadow-lg rounded' }}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-warning text-warning m-0 d-100">
            <FontAwesomeIcon
              icon={['fas', 'circle-exclamation']}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <p className="font-weight-bold font-size-xl mt-4">{title}</p>
        <p className="font-size-md text-black-50">{description}</p>
        <div className="pt-4">
          <Button onClick={() => onClose()} className="btn-neutral-dark mx-3">
            <span className="btn-wrapper--label">Cancel</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ModalAlert.defaultProps = {
  title: 'Alert',
  description: '',
  open: false,
  onClose: () => {}
};

ModalAlert.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default ModalAlert;
