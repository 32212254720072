import React, { useEffect, useState, useMemo } from 'react';
import cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import Style from './ShipmentRouteForm.module.css';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { ForwarderService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import { useDispatch } from 'react-redux';
import RateSheetService from 'service/RateSheetService';
import CountrySelectNew from 'components/CountrySelect/CountrySelectNew';
import StateSelect from 'components/CountrySelect/StateSelect';
import CitySelect from 'components/CountrySelect/CitySelect';
import PortCodeSelect from 'components/CountrySelect/PortCodeSelect';
import { TRANSPORT_MODES_LIST } from 'util/constants';
import StyledSelect from 'components/StyledSelect';
import { useForm } from 'util/hooks';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';

/* eslint-disable */
const routeInfoFields = [
  {
    name: 'transportMode',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select a transport mode'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'originCountry',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select an origin country'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'originState',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select an origin state'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'originCity'
    // isRequired: true,
    // validate: (value) => {
    //   if (value === null || value === '' || value.code == '') {
    //     return {
    //       pass: false,
    //       errorMessage: 'Please select an origin city'
    //     };
    //   }

    //   return {
    //     pass: true
    //   };
    // }
  },
  {
    name: 'originPort',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please enter an origin port'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'destinationCountry',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select a destination country'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'destinationState',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select a destination state'
        };
      }

      return {
        pass: true
      };
    }
  },
  {
    name: 'destinationCity'
    // isRequired: true,
    // validate: (value) => {
    //   if (value === null || value === '' || value.code == '') {
    //     return {
    //       pass: false,
    //       errorMessage: 'Please select a destination city'
    //     };
    //   }

    //   return {
    //     pass: true
    //   };
    // }
  },
  {
    name: 'destinationPort',
    isRequired: true,
    validate: (value) => {
      if (value === null || value === '') {
        return {
          pass: false,
          errorMessage: 'Please select a destination port'
        };
      }

      return {
        pass: true
      };
    }
  }
];

const ShipmentRouteForm = ({
  info,
  run,
  setRun,
  toggleRightDrawer,
  onUpdate,
  isMakeOffer,
  fieldsValidCallback,
  withErrors
}) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);

  const [loading, setLoading] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);

  //   start

  const { transportMode, origin, destination } = info || {};

  const [formattedInfoState, setFormattedInfoState] = useState();

  const initializeFields = (data) => {
    if (data.transportMode || data.origin || data.destination) {
      const updatedFields = cloneDeep(routeInfoFields);
      updatedFields[0].initialValue = data.transportMode;
      updatedFields[1].initialValue = data.origin?.country;
      updatedFields[2].initialValue = data.origin?.state;
      updatedFields[3].initialValue = data.origin?.city;
      updatedFields[4].initialValue = data.origin?.port;
      updatedFields[5].initialValue = data.destination?.country;
      updatedFields[6].initialValue = data.destination?.state;
      updatedFields[7].initialValue = data.destination?.city;
      updatedFields[8].initialValue = data.destination?.port;

      return updatedFields;
    }

    return routeInfoFields;
  };

  const updatedInfoFields = useMemo(
    () =>
      initializeFields({
        transportMode,
        origin,
        destination
      }),
    [transportMode, origin, destination]
  );

  const [infoState, infoDispatch, infoFieldsAreValid, infoErrors] = useForm(
    updatedInfoFields,
    {
      debounceValidation: 200
    }
  );

  const [showErrors, setShowErrors] = useState(() =>
    updatedInfoFields
      .map((field) => field.name)
      .reduce((stateMap, key) => {
        const tempStateMap = stateMap;
        tempStateMap[key] = false;
        return tempStateMap;
      }, {})
  );

  useEffect(() => {
    if (showAllErrors) {
      setShowErrors(
        updatedInfoFields
          .map((field) => field.name)
          .reduce((stateMap, key) => {
            const tempStateMap = stateMap;
            tempStateMap[key] = true;
            return tempStateMap;
          }, {})
      );
    }
  }, [showAllErrors, updatedInfoFields]);

  useEffect(() => {
    // onUpdate(infoState);
    console.log('kvfbjgnb', infoState);
    // fieldsValidCallback(infoFieldsAreValid);
    const tempData = {
      origin: {
        state: infoState.originState,
        port: infoState.originPort,
        city: infoState.originCity,
        country: infoState.originCountry
      },
      destination: {
        state: infoState.destinationState,
        port: infoState.destinationPort,
        city: infoState.destinationCity,
        country: infoState.destinationCountry
      },
      transport_mode: infoState.transportMode
    };
    setFormattedInfoState(tempData);

    // if (infoFieldsAreValid && isMakeOffer) {
    //   onUpdate({
    //     origin: tempData.origin,
    //     destination: tempData.destination,
    //     transportMode: tempData.transport_mode
    //   });
    // }
  }, [infoState, infoFieldsAreValid]);

  const updateField = (value, name) => {
    console.log('updateField routeField', name, value);

    setShowErrors({ ...showErrors, [name]: false });

    infoDispatch({
      type: 'UPDATE_FIELD',
      name,
      value
    });
  };

  //   End

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!infoFieldsAreValid) {
      setShowAllErrors(true);
      return;
    } else {
      setShowAllErrors(false);
    }
    setLoading(true);
    if (info & !isMakeOffer) {
      RateSheetService.editRateSheet(
        auth.accountId,
        info._id,
        formattedInfoState,
        auth.token
      ).then((response) => {
        if (response.message) {
          // window.alert(data.message)
          notify(dispatch, 'success', response.message);
          setRun(!run);
          toggleRightDrawer(false);
        } else {
          // window.alert(data.error)
          notify(dispatch, 'error', response.error);
        }
        setLoading(false);
      });
    } else {
      RateSheetService.createRateSheet(
        auth.accountId,
        formattedInfoState,
        auth.token
      ).then((response) => {
        if (response.message) {
          // window.alert(data.message)
          if (isMakeOffer) {
            onUpdate(response.data);
          }
          notify(dispatch, 'success', response.message);
          setRun(!run);
          toggleRightDrawer(false);
        } else if (response.error && response.data) {
          if (isMakeOffer) {
            onUpdate(response.data);
          }
          notify(
            dispatch,
            'success',
            'An existing route was found and retrieved'
          );
          toggleRightDrawer(false);
        } else {
          // window.alert(data.error)
          notify(dispatch, 'error', response.error);
        }
        setLoading(false);
      });
    }
  };

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={Style.container}>
        <div className={`d-flex align-items-center my-2`}>
          <span className="font-weight-bold font-size-lg">
            {info ? 'Edit' : 'Create'} Route
          </span>
        </div>
        <div className="divider my-3" />
        <div className="mt-4 mb-3">
          <span className="font-size-md font-weight-bold">
            Select Transport Mode
          </span>
        </div>
        <div className={Style.OneFieldContainer}>
          <StyledSelect
            label="Select Transport Mode"
            name="transportMode"
            margin="none"
            value={infoState.transportMode}
            onChange={(e) => updateField(e.target.value, 'transportMode')}
            size="small"
            options={
              TRANSPORT_MODES_LIST &&
              TRANSPORT_MODES_LIST.map((item, index) => {
                return {
                  label: item.label,
                  value: item.value
                };
              })
            }
            error={
              showErrors.transportMode && infoErrors.transportMode
                ? true
                : false
            }
            onBlur={() => {
              setShowErrors({ ...showErrors, transportMode: true });
            }}
            required={true}
            helperText={infoErrors.transportMode}
          />
        </div>
        <div className="mt-4 mb-3">
          <span className="font-size-md font-weight-bold">
            Select Origin Port
          </span>
        </div>
        <div className={Style.TwoFieldContainer}>
          <CountrySelectNew
            name="originCountry"
            useDefault
            isClearable={true}
            isMulti={false}
            onChange={(e, opt) => updateField(opt, 'originCountry')}
            showError={
              withErrors && showErrors.originCountry && infoErrors.originCountry
                ? true
                : false
            }
            error={infoErrors.originCountry}
            onBlur={() => {
              setShowErrors({ ...showErrors, originCountry: true });
            }}
            value={infoState.originCountry}
            required
            disabled={isMakeOffer}
          />
          <StateSelect
            name="originState"
            country={infoState.originCountry?.code}
            value={infoState.originState}
            required
            showError={
              withErrors && showErrors.originState && infoErrors.originState
                ? true
                : false
            }
            error={infoErrors.originState}
            onBlur={() => {
              setShowErrors({ ...showErrors, originState: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'originState')}
          />
          <PortCodeSelect
            name="originPort"
            value={infoState.originPort}
            required
            city={infoState.originState?.code}
            transportMode={infoState.transportMode}
            showError={
              withErrors && showErrors.originPort && infoErrors.originPort
                ? true
                : false
            }
            error={infoErrors.originPort}
            onBlur={() => {
              setShowErrors({ ...showErrors, originPort: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'originPort')}
          />
          <CitySelect
            name="originCity"
            value={infoState.originCity}
            state={infoState.originState?.code}
            placeholder="Select City (optional)"
            showError={
              withErrors && showErrors.originCity && infoErrors.originCity
                ? true
                : false
            }
            error={infoErrors.originCity}
            onBlur={() => {
              setShowErrors({ ...showErrors, originCity: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'originCity')}
          />
        </div>
        <div className="mt-4 mb-3">
          <span className="font-size-md font-weight-bold">
            Select Destination Port
          </span>
        </div>
        <div className={Style.TwoFieldContainer}>
          <CountrySelectNew
            name="destinationCountry"
            useDefault
            isClearable={true}
            isMulti={false}
            onChange={(e, opt) => updateField(opt, 'destinationCountry')}
            showError={
              withErrors &&
              showErrors.destinationCountry &&
              infoErrors.destinationCountry
                ? true
                : false
            }
            error={infoErrors.destinationCountry}
            onBlur={() => {
              setShowErrors({ ...showErrors, destinationCountry: true });
            }}
            value={infoState.destinationCountry}
            required
            disabled={isMakeOffer}
          />
          <StateSelect
            name="destinationState"
            country={infoState.destinationCountry?.code}
            value={infoState.destinationState}
            required
            showError={
              withErrors &&
              showErrors.destinationState &&
              infoErrors.destinationState
                ? true
                : false
            }
            error={infoErrors.destinationState}
            onBlur={() => {
              setShowErrors({ ...showErrors, destinationState: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'destinationState')}
          />
          <PortCodeSelect
            name="destinationPort"
            value={infoState.destinationPort}
            required
            city={infoState.destinationState?.code}
            transportMode={infoState.transportMode}
            showError={
              withErrors &&
              showErrors.destinationPort &&
              infoErrors.destinationPort
                ? true
                : false
            }
            error={infoErrors.destinationPort}
            onBlur={() => {
              setShowErrors({ ...showErrors, destinationPort: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'destinationPort')}
          />
          <CitySelect
            name="destinationCity"
            value={infoState.destinationCity}
            state={infoState.destinationState?.code}
            placeholder="Select City (optional)"
            showError={
              withErrors &&
              showErrors.destinationCity &&
              infoErrors.destinationCity
                ? true
                : false
            }
            error={infoErrors.destinationCity}
            onBlur={() => {
              setShowErrors({ ...showErrors, destinationCity: true });
            }}
            handleChange={(e, opt) => updateField(opt, 'destinationCity')}
          />
        </div>

        <div className="d-flex justify-content-end mt-5">
          <Button
            style={{ textTransform: 'none', backgroundColor: '#4431bb' }}
            variant="contained"
            size="large"
            onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

ShipmentRouteForm.defaultProps = {
  info: {
    origin: {
      state: '',
      port: '',
      city: '',
      country: ''
    },
    destination: {
      state: '',
      port: '',
      city: '',
      country: ''
    },
    transportMode: ''
  },
  isMakeOffer: false,
  withErrors: true,
  fieldsValidCallback: () => {},
  onUpdate: () => {}
};

ShipmentRouteForm.propTypes = {
  info: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onUpdate: PropTypes.func.isRequired,
  isMakeOffer: PropTypes.bool,
  withErrors: PropTypes.bool,
  fieldsValidCallback: PropTypes.func
};
/* eslint-enable */
export default ShipmentRouteForm;
