import { getBaseUrl } from 'config/config';
import { HttpMethods, HttpService } from './index';

export default class AuthService {
  static async check2FA(email, password) {
    const url = 'admin/signin-check';
    const payload = {
      email: email,
      password: password
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
    // return HttpService.request(HttpMethods.POST, url, payload)
    // .then((res) => res)
    // .catch((err) => console.error(err));
  }

  static async loginWithGoogle(user, token) {
    const url = 'admin/signin-with-google';
    const payload = {
      user
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
  }

  static login(email, password, accountType) {
    const url = 'admin/signin';
    const payload = {
      email: email,
      password: password
    };
    return HttpService.request(HttpMethods.POST, url, payload)
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static async forgotPasswordMail(email) {
    const url = 'admin/auth/fpSendMail';
    const payload = {
      email: email
    };
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static async forgotPasswordEnterPassword(data) {
    const url = 'admin/auth/fpChangePassword';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static async updateAdminInfo(data) {
    const url = 'admin/auth/updateAdminInfo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static async uploadProfileAvtar(data, token) {
    const url = 'admin/auth/uploadProfileAvtar';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    return result;
  }

  static async deleteProfileAvtar(data, token) {
    const url = 'admin/auth/deleteProfileAvtar';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    const result = await response.json();
    return result;
  }

  static async uploadCompanyLogo(data) {
    const url = 'admin/auth/uploadCompanyLogo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: data
    });
    const result = await response.json();
    return result;
  }

  static async deleteCompanyLogo(data) {
    const url = 'admin/auth/deleteCompanyLogo';
    const response = await fetch(getBaseUrl() + url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    return result;
  }

  static isAuthenticated(user) {
    return user;
  }

  static sendVerificationCode(payload) {
    return HttpService.request(
      HttpMethods.POST,
      'verify/sendCode',
      payload
    ).then((res) => {
      return res;
    });
  }

  static verifyCode(payload) {
    return HttpService.request(
      HttpMethods.POST,
      'verify/checkCode',
      payload
    ).then((res) => {
      return res;
    });
  }

  static existingEmail(email, type) {
    const url = 'user/exists';

    const payload = {
      collectionName: type,
      email: email
    };
    return HttpService.request(HttpMethods.POST, url, payload)
      .then((res) => res)
      .catch((err) => console.error(err));
  }

  static sendPwdResetLink(payload) {
    return HttpService.request(HttpMethods.POST, 'resetPwdLink', payload).then(
      (res) => {
        return res;
      }
    );
  }

  static verifyPwdResetToken(token) {
    return HttpService.request(HttpMethods.GET, `resetPwdLink/${token}`).then(
      (res) => {
        return res;
      }
    );
  }

  static resetPwd(payload) {
    return HttpService.request(HttpMethods.POST, 'resetPwd', payload).then(
      (res) => {
        return res;
      }
    );
  }

  static async logout(token) {
    const url = `${getBaseUrl()}admin/signout`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const result = await response.json();
    return result;
    // return HttpService.request(
    //   HttpMethods.GET,
    //   'admin/signout',
    //   {},
    //   {},
    //   token
    // ).then((res) => res);
  }
}
