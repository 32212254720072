import { getBaseUrl } from '../config/config';
import { HttpMethods, httpService } from './global';

export default class AdminTeam {
  static async inviteTeam(data, token) {
    const url = 'admin/team/invite-team';

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'team',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async isTokenExpired(token) {
    const url = `admin/team/is-token-expired?token=${token}`;

    const result = await fetch(getBaseUrl() + url);
    const response = await result.json();
    return response;
  }

  static async registerTeam(data) {
    const url = 'admin/team/register-team';
    const result = await fetch(getBaseUrl() + url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POSt'
    });
    const response = await result.json();
    return response;
  }

  static async fetch_team(admin_id, token, page, search) {
    const url = `admin/team/fetch-team?admin_id=${admin_id}&page=${page}&search=${search}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'team',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async pendingInvites(token, page, search, limit) {
    const url = `admin/team/pending-invite?page=${page}&search=${search}&limit=${limit}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'team',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async revokeTeamMember(id, token) {
    const url = `admin/team/revoke-invite/${id}`;

    return httpService(
      HttpMethods.PUT,
      url,
      {},
      {
        token,
        type: 'team',
        method: 'edit',
        platform: 'Admin'
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async update_team_member_status(data, token) {
    const url = 'admin/team/update-team-member-status';

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'team',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async get_team_member_by_id(id, token) {
    const url = `admin/team/get-team-member-by-id?id=${id}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'team',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
  static async updatePermission(id, type, token) {
    const url = `admin/team/update-permission?id=${id}&type=${type}`;

    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'team',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async removeTeamMember(id, cid, token) {
    const url = `admin/team/remove-member?id=${id}&cid=${cid}`;

    return httpService(
      HttpMethods.DELETE,
      url,
      {},
      {
        token,
        type: 'team',
        method: 'delete',
        platform: 'Admin'
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchMemberPermission(id, name, token) {
    const url = `${getBaseUrl()}admin/team/fetch-admin-permission?id=${id}&name=${name}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async fetchTeamRoles(token) {
    const url = `${getBaseUrl()}admin/fetch-team-roles`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }

  static async update_role(token, data) {
    const url = 'admin/team/update-role';

    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'team',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchAdminActivity(admin_id, company_id, token, skip) {
    const url = `${getBaseUrl()}admin/team/fetch-admin-activity?admin_id=${admin_id}&company_id=${company_id}&skip=${skip}`;
    const result = await fetch(url, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
    const response = await result.json();
    return response;
  }
}
