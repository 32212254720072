import React from 'react';
import PageContent from 'sites/shipper/components/layout/PageContent';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import { LeadsManagementTable } from './components';

export default function LeadsManagement() {
  return (
    <>
      <PageTitle titleHeading={'Shipment Leads Notification Mgmt'}></PageTitle>
      <PageContent>
        <LeadsManagementTable />
      </PageContent>
    </>
  );
}
