import AdminTeam from 'service/AdminTeam';
import Style from '../company_team.module.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSelection from 'antd/es/table/hooks/useSelection';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';
import { notify } from 'sites/shipper/components/Toast/toast';

export function PendingTeam() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [run, setRun] = useState(true);
  const auth = useSelector((state) => state.session.auth);

  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const [profileData, setProfileData] = useState(null);

  const [confirmRevoke, setConfirmRevoke] = useState(false);

  const history = useHistory();
  const [search, setSearch] = useState('');

  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(location.search);
  const [memberCount, setMemberCount] = useState(0);

  const payValue = searchParams.get('page') ? searchParams.get('page') : 1;
  const [page, setpage] = useState(parseInt(payValue));
  const itemsPerPage = 15;

  const [check, setCheck] = useState(true);

  function handlePageChange(event, value) {
    setpage(value);
    setCheck(!check);
    history.push(`/team?tab=pending&page=${value}`);
  }

  useEffect(() => {
    async function fetchData() {
      const res = await AdminTeam.pendingInvites(
        auth.token,
        page,
        search,
        itemsPerPage
      );
      if (res.error) {
        setLoading(false);
      } else {
        setData(res.data);
        setMemberCount(res.metadata.totalItems);
        setLoading(false);
      }
    }
    fetchData();
  }, [auth, run, page, search]);

  const revokeProfile = async () => {
    const res = await AdminTeam.revokeTeamMember(profileData._id, auth.token);
    setLoading(true);
    if (res.error) {
      console.log(res.error);
    } else {
      setLoading(false);
      notify(dispatch, 'success', res.message);
      setRun(!run);
    }
  };

  const TableHeading = [
    'S.No',
    'Name',
    'Email',
    'Status',
    'Department',
    'Date Created',
    'Action'
  ];

  const TableData = data.map((item, index) => [
    index + 1,
    `${item.firstName} ${item.lastName}`,
    item.email,
    item.status,
    item.department,
    moment(item.createdAt).format('LL'),
    <>
      <div className="position-relative">
        <center>
          <Tooltip title="More" arrow>
            <Button
              variant="text"
              className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
              onClick={(e) => {
                setOpenMoreOptions(e.currentTarget);
                setMoreOptionClicked(item);
              }}>
              <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
            </Button>
          </Tooltip>
        </center>
        {moreOptionClicked?.id === item?.id && (
          <SmallPopupWrapper
            open={openMoreOptions}
            onClose={() => {
              setMoreOptionClicked(null);
              setOpenMoreOptions(null);
            }}>
            <MenuItem
              onClick={() => {
                setOpenMoreOptions(null);
                setConfirmRevoke(true);
                setProfileData(moreOptionClicked);
              }}>
              Revoke
            </MenuItem>
          </SmallPopupWrapper>
        )}
      </div>
    </>
  ]);
  return (
    <>
      <div className={Style.container}>
        <TableWithSearchBar
          loading={loading}
          animatedSearchbar={{
            show: false
          }}
          tableMainHeading={'Pending Team Members'}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'0.5fr 1.5fr repeat(5,1fr)'}
          actionButtons={[
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>
          ]}
          pagination={{
            count: Math.ceil(memberCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
        />
      </div>

      {/* Edit form */}
      <div className={`position-relative ${Style.action_container}`}>
        <ConfirmDialog
          question="Are you sure you want to revoke this pending invitation? The user will no longer be able to join the team with the current invite."
          heading="Revoke Invitation"
          confirmDialogFunction={revokeProfile}
          confirmDialogOpen={confirmRevoke}
          setConfirmDialogOpen={setConfirmRevoke}
        />
      </div>
    </>
  );
}
