import { Button, Tooltip, MenuItem } from '@material-ui/core';
import { Empty } from 'antd';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { notify } from 'sites/shipper/components/Toast/toast';
import CachedIcon from '@mui/icons-material/Cached';
import LeadService from 'service/LeadService';
import SmallPopupWrapper from 'components/Common/SmallPopupWrapper/SmallPopupWrapper';
import { userPermission } from 'util/adminPermissionRoles';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { LeadsManagementModal } from './leads-mgmt-modal';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';

export function LeadsManagementTable() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.session.auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 15;
  const [shipperCount, setShipperCount] = useState(0);
  const history = useHistory();

  const [check, setCheck] = useState(true);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [subscriberData, setSubscriberData] = useState(null);
  const [removeId, setRemoveId] = useState(false);
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);

  const [open, setOpen] = useState(false);
  const [run, setRun] = useState(true);

  const handleModal = () => {
    setOpen(() => !open);
  };

  const handleRun = () => {
    setRun(() => !run);
  };

  const handleDelete = async () => {
    setLoading(true);
    const result = await LeadService.removeUser(removeId, auth.token);
    if (result.error) {
      notify(dispatch, 'error', result.error);
    } else {
      notify(dispatch, 'success', result.message);
      setRun(!run);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchShippers = async () => {
      setLoading(true);
      try {
        const result = await LeadService.fetchLead(page, search, auth.token);

        if (result?.data) {
          setData(result.data);
          setShipperCount(result.metadata.totalItems);
          setLoading(false);
        } else {
          setLoading(false);
          setData([]);
        }
      } catch (error) {
        setLoading(false);
        notify(dispatch, 'error', 'Failed to fetch records');
      }
    };

    fetchShippers();
  }, [run, page, search]);

  useEffect(() => {
    if (loading) return;

    const element = document.getElementById('top');
    element.scrollIntoView({ behavior: 'smooth' });
  }, [check]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setCheck(!check);
    history.push(`/shipment-lead-mgmt?page=${value}`);
  };

  const TableHeading = [
    'S.No.',
    'Name',
    'Email',
    'Status',
    'Created At',
    'Actions'
  ];

  const TableData = data.map((item, index) => [
    index + 1,
    `${item.adminId.firstName} ${item.adminId.lastName}`,
    item.adminId.email,
    item.adminId.status,
    moment(item.createdAt).format('LL'),
    <>
      <div className="position-relative">
        <center>
          <Tooltip title="More" arrow>
            <Button
              variant="text"
              className="btn-outline-primary d-flex align-items-center justify-content-center d-30 p-0 rounded-pill shadow-none"
              onClick={(e) => {
                setOpenMoreOptions(e.currentTarget);
                setMoreOptionClicked(item);
              }}>
              <MoreVertTwoToneIcon style={{ fontSize: '18px' }} />
            </Button>
          </Tooltip>
        </center>
        {moreOptionClicked?.id === item?.id && (
          <SmallPopupWrapper
            open={openMoreOptions}
            onClose={() => {
              setMoreOptionClicked(null);
              setOpenMoreOptions(null);
            }}>
            {userPermission.lead.delete.includes(auth.accountCmpyLinkType) && (
              <MenuItem
                onClick={() => {
                  setConfirmSubmit(true);
                  setRemoveId(moreOptionClicked?._id);
                  setOpenMoreOptions(null);
                }}>
                Remove
              </MenuItem>
            )}
          </SmallPopupWrapper>
        )}
      </div>
    </>
  ]);

  return (
    <>
      {open && (
        <LeadsManagementModal
          open={open}
          handleModal={handleModal}
          handleRun={handleRun}
        />
      )}
      <div id="top">
        {/* <LoaderComponent loading={loading} /> */}
        <TableWithSearchBar
          animatedSearchbar={{
            show: true,
            value: search,
            onChange: (value) => setSearch(value),
            setLoading: setLoading
          }}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'0.1fr repeat(5, 1fr)'}
          pagination={{
            count: Math.ceil(shipperCount / itemsPerPage),
            page: page,
            onChange: handlePageChange
          }}
          actionButtons={[
            userPermission.lead.create.includes(auth.accountCmpyLinkType) && (
              <Button
                variant="contained"
                className="btn-primary shadow-none py-2"
                onClick={() => {
                  setOpen(true);
                  setSubscriberData(null);
                }}>
                Add User
              </Button>
            )
          ]}
        />
      </div>

      <ConfirmDialog
        confirmDialogFunction={handleDelete}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure you want to remove this user`}
      />
    </>
  );
}
