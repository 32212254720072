import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, IconButton } from '@material-ui/core';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Style from './Summary.module.css';
import { TagManagmentService, SourceManagmentService } from 'service';
import { SubSystemType } from 'util/subSystemType';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import StyledSelect from 'components/StyledSelect';
import AssignForwarderPopup from 'components/Common/AssignForwarderPopup';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@material-ui/icons';

/**
 * MetaDetails Component
 *
 * This component handles the management of metadata such as tags and sources
 * for a given rate sheet. It fetches and displays available tags and sources,
 * allowing the user to assign them.
 *
 * @component
 * @param {Function} handleAdditionalData - Callback function to update parent component state.
 * @param {Object} additionalData - Existing metadata details (tags, source, etc.).
 */

const MetaDetails = ({ handleAdditionalData, additionalData }) => {
  const auth = useSelector((state) => state.session.auth);

  const [tags, setTags] = useState([]);
  const [sources, setSources] = useState([]);

  useEffect(() => {
    fetchTags();
    fetchSources();
  }, []);

  /**
   * Fetches available tags and updates the state.
   */
  const fetchTags = () => {
    const requestData = {
      type: SubSystemType.RATE_SHEET,
      is_active: true
    };
    TagManagmentService.fetchTag(
      requestData,
      auth.token,
      'rate_sheet',
      'create'
    ).then((res) => {
      if (!res.error) {
        setTags(
          res.data.map((item) => ({ label: item.name, value: item._id }))
        );
      } else {
        console.error('Error fetching tags:', res.error);
      }
    });
  };

  /**
   * Fetches available sources and updates the state.
   */
  const fetchSources = () => {
    const requestData = {
      type: SubSystemType.RATE_SHEET,
      is_active: true
    };
    SourceManagmentService.fetchSource(
      requestData,
      auth.token,
      'rate_sheet',
      'create'
    ).then((res) => {
      if (!res.error) {
        setSources([
          { value: '', label: 'None' },
          ...res.data.map((item) => ({
            label: item.name,
            value: item._id,
            type: item.type
          }))
        ]);
      } else {
        console.error('Error fetching sources:', res.error);
      }
    });
  };

  /**
   * Handles the source selection from the dropdown.
   * Updates the parent component with the selected source details.
   */
  const handleSources = (event) => {
    const selectedType = event.target.value || '';
    const typeObj = sources.find((opt) => opt.value === selectedType);

    handleAdditionalData('source', {
      id: typeObj?.value,
      name: typeObj?.label,
      type: typeObj?.type,
      companyId: null,
      companyName: null
    });
  };

  return (
    <div className={Style.main}>
      <div className="d-flex flex-column c-gap-1">
        <span className={Style.detail_text_total}>Meta Details</span>
        <Divider />
      </div>
      <div className={Style.flex_col}>
        <span className={Style.detail_text}>Select Tags</span>
        <Select
          options={tags}
          mode="multiple"
          placeholder="Select Tag"
          defaultValue={additionalData?.tag}
          onChange={(value) => handleAdditionalData('tag', value)}
        />
      </div>
      <div className={Style.flex_col}>
        <div className={Style.sourceDiv}>
          <StyledSelect
            label="Select Source"
            name="source"
            margin="0"
            value={additionalData?.source?.id}
            onChange={handleSources}
            size="small"
            options={sources}
          />
          <RenderSourceInfo
            source={additionalData.source}
            handleAdditionalData={handleAdditionalData}
          />
        </div>
      </div>
    </div>
  );
};

MetaDetails.propTypes = {
  handleAdditionalData: PropTypes.func.isRequired,
  additionalData: PropTypes.shape({
    tag: PropTypes.array,
    source: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      companyId: PropTypes.string,
      companyName: PropTypes.string
    })
  })
};

MetaDetails.defaultProps = {
  additionalData: {
    tag: [],
    source: {
      id: '',
      name: '',
      type: '',
      companyId: '',
      companyName: ''
    }
  }
};

export default MetaDetails;

/**
 * RenderSourceInfo Component
 *
 * Displays assigned forwarder information and provides options to edit or remove it.
 *
 * @param {Object} source - The source object containing forwarder details.
 * @param {Function} handleAdditionalData - Callback to update parent state.
 */

const RenderSourceInfo = ({ source, handleAdditionalData }) => {
  const [open, setOpen] = useState(false);

  if (
    source?.type !== SubSystemType.RATE_SHEET ||
    (source?.type === SubSystemType.RATE_SHEET &&
      source?.name?.toLowerCase() !== 'kargoplex forwarder')
  ) {
    return null;
  }

  return (
    <>
      {source?.companyName ? (
        <div className="d-flex c-gap-half align-items-center font-size-xs">
          <span className="font-weight-bold">Company name:</span>
          <span>{source?.companyName}</span>
          <IconButton
            onClick={() => setOpen(true)}
            style={{ width: '24px', height: '24px', margin: '0 0 0 10px' }}>
            <EditIcon className="text-primary" style={{ fontSize: '1rem' }} />
          </IconButton>
          <IconButton
            onClick={() =>
              handleAdditionalData('source', {
                id: source?.id,
                name: source?.name,
                type: source?.type,
                companyId: null,
                companyName: null
              })
            }
            style={{ width: '24px', height: '24px' }}>
            <Delete className="text-primary" style={{ fontSize: '1rem' }} />
          </IconButton>
        </div>
      ) : (
        <div>
          <Button
            className="btn-primary"
            size="small"
            onClick={() => setOpen(true)}>
            Assign Forwarder
          </Button>
        </div>
      )}

      {open && (
        <AssignForwarderPopup
          open={open}
          setOpen={setOpen}
          callBack={(selectedForwarder) => {
            handleAdditionalData('source', {
              id: source?.id,
              name: source?.name,
              type: source?.type,
              companyId: selectedForwarder?.companyId || null,
              companyName: selectedForwarder?.companyName || ''
            });

            setOpen(false);
          }}
        />
      )}
    </>
  );
};

RenderSourceInfo.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string
  }),
  handleAdditionalData: PropTypes.func.isRequired
};

RenderSourceInfo.defaultProps = {
  source: {
    id: '',
    name: '',
    type: '',
    companyId: '',
    companyName: ''
  }
};
