import React, { useEffect, useState } from 'react';
import PageTitle from 'sites/shipper/components/layout/PageTitle/PageTitle';
import PageContent from 'sites/shipper/components/layout/PageContent';
import { useHistory } from 'react-router-dom';
import Tabs from 'components/Tabs';
import { ActiveTeam } from './components/active-team';
import { PendingTeam } from './components/pending-team';

/* eslint-disable */
const AdminTeamPage = () => {
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');

  const content = [
    { label: 'Active Team', value: 'active', View: <ActiveTeam /> },
    { label: 'Pending Team', value: 'pending', View: <PendingTeam /> }
  ];
  return (
    <>
      <div className="app-inner-content-layout--main p-0">
        <PageTitle titleHeading="Team Managment" />
        <PageContent>
          <Tabs
            content={content}
            active={tab || content[0].value}
            onClick={(e) => history.push(`/team?tab=${e}`)}
          />
        </PageContent>
      </div>
    </>
  );
};

// export default compose(withStyles(styles), withWidth())(AdminTeamPage);
export default AdminTeamPage;
/* eslint-enable */
