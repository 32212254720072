import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlogService, CareerService } from 'service';
import { notify } from 'sites/shipper/components/Toast/toast';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import StyledSelect from 'components/StyledSelect';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';

const AddCareer = ({
  open,
  setOpen,
  // handleClose,
  setRun,
  run,
  careerData,
  setCareerData
}) => {
  const [details, setDetails] = useState({
    title: '',
    description: '',
    jobType: '',
    jobLocation: '',
    applyLink: '',
    slug: ''
  });

  const [error, setError] = useState('');
  const [slugerror, setSlugError] = useState('');

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.session.auth);
  const [loading, setLoading] = useState(false);
  const resetDetails = () => {
    setDetails({
      ...details,
      title: '',
      description: '',
      jobType: '',
      jobLocation: '',
      applyLink: '',
      slug: ''
    });
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
    resetDetails();
    setSlugError('');
    setCareerData(null);
  };

  useEffect(() => {
    if (careerData) {
      setDetails(careerData);
    } else {
      resetDetails();
    }
  }, [careerData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'slug') {
      setSlugError('');
      let newSlug = value.toLowerCase(); // Convert input to lowercase
      newSlug = newSlug.replace(/\s+/g, '-'); // Replace spaces with hyphens
      newSlug = newSlug.replace(/[^\w\s-]/g, '-'); // Replace special characters with hyphens

      setDetails((prev) => {
        return {
          ...prev,
          [name]: newSlug
        };
      });
    } else {
      setDetails((prev) => {
        return {
          ...prev,
          [name]: value
        };
      });
    }
  };

  const addCareer = async () => {
    try {
      setLoading(true);
      const data_to_send = {
        ...details,
        userId: auth.accountId
      };

      CareerService.createCareer(data_to_send, auth.token).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          notify(dispatch, 'success', res.message);
          setRun(!run);
          handleClose();
        }
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to send data');
    }
  };
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const editCareer = async () => {
    try {
      setLoading(true);

      const data_to_send = {
        ...details,
        userId: auth.accountId
      };

      CareerService.updateCareerDetails(data_to_send, auth.token).then(
        (res) => {
          if (res.error) {
            setError(res.error);
          } else {
            notify(dispatch, 'success', res.message);
            setRun(!run);
            setCareerData(null);
            setDetails({
              title: '',
              description: '',
              jobType: '',
              jobLocation: '',
              applyLink: '',

              slug: ''
            });
            handleClose();
          }
          setLoading(false);
        }
      );
    } catch (e) {
      console.log(e);
      setLoading(false);
      notify(dispatch, 'error', 'Unable to edit data');
    }
  };

  const check_slug_availability = () => {
    if (!details.slug) {
      return;
    }
    setSlugError('');
    CareerService.checkSlugAvailability(
      { slug: details.slug, career_id: details._id ? details._id : null },
      auth.token
    ).then((res) => {
      if (res.error) {
        setSlugError(res.error);
      } else {
        setSlugError('');
      }
    });
  };

  return (
    <div>
      {loading && <LoaderComponent loading={loading} />}

      <ModalSimple
        modalTitle={'Add Career'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        disabled={slugerror ? true : false}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={handleClose}
        actionButtonText={careerData ? 'Edit' : 'Create'}>
        <StyledInput
          name="title"
          label="Title"
          value={details.title}
          onChange={handleChange}
        />

        <StyledInput
          name="description"
          label="Description"
          value={details.description}
          onChange={handleChange}
        />

        {/* <StyledInput
          name="jobType"
          label="Job Type"
          value={details.jobType}
          onChange={handleChange}
        /> */}

        <StyledSelect
          id="jobType"
          name="jobType"
          label="Job Type"
          value={details.jobType}
          onChange={handleChange}
          options={[
            { label: 'Full Time', value: 'Full Time' },
            { label: 'Part Time', value: 'Part Time' },
            { label: 'Internship', value: 'Internship' }
          ]}
        />

        <StyledInput
          name="jobLocation"
          label="Job Location"
          value={details.jobLocation}
          onChange={handleChange}
        />

        <StyledInput
          name="applyLink"
          label="Apply Link"
          value={details.applyLink}
          onChange={handleChange}
        />

        <div>
          <StyledInput
            name="slug"
            label="Slug"
            value={details.slug}
            onChange={handleChange}
            onBlur={check_slug_availability}
          />
          {slugerror && (
            <span style={{ color: 'red', fontSize: '12px' }}>{slugerror}</span>
          )}
        </div>

        {error && (
          <span style={{ color: 'red', fontSize: '12px' }}>{error}</span>
        )}
      </ModalSimple>
      <ConfirmDialog
        confirmDialogFunction={careerData ? editCareer : addCareer}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={`Are you sure to save`}
      />
    </div>
  );
};

export default AddCareer;
