import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PortAddress from './PortAddress';

const mapStateToProps = (state) => ({
  user: state.user.info
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PortAddress);
