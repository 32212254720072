import { Button } from '@material-ui/core';
import Style from './TeamMemberRegistration.module.css';
import React from 'react';

export function TeamMemberRegistrationExipred() {
  return (
    <div
      className={`${Style.container}`}
      style={{
        backgroundImage: 'url(assets/authentication/team_register_back.png)'
      }}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flex: '1' }}>
        <div
          className="d-flex flex-column align-items-center c-gap-1 rounded bg-white p-4"
          style={{ width: 'max-content' }}>
          <span className={Style.form_legend}>Invalid or expired Token</span>
          {/* <a href='/login'>Click Here to login</a></div> */}
          <img
            src="assets\authentication\invalid_token.svg"
            alt="Invalid Token"
            style={{ maxWidth: '320px' }}
          />
          <div className={Style.form_actions_container}>
            <Button className={`${Style.signin_btn} btn-primary`} href="/login">
              Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
