import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RateSheetService from 'service/RateSheetService';
import { Button, Tooltip, ButtonGroup } from '@material-ui/core';
import ModalPopupWrapper from 'components/Common/ModalPopup/ModalPopup';
import Preview from 'sites/shipper/pages/Chat/ChatViewPane/OfferChat/Preview/Preview';
import CachedIcon from '@mui/icons-material/Cached';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewAllPrices from '../ViewAllPrices/ViewAllPrices';
import ViewCompactTwoToneIcon from '@material-ui/icons/ViewCompactTwoTone';
import GridOnTwoToneIcon from '@material-ui/icons/GridOnTwoTone';
import clsx from 'clsx';
import GridViewRateSheet from 'components/Tables/GridViewRateSheet/GridViewRateSheet';
import ShippingLineCard from '../Reuseables/ShippingLineCard/ShippingLineCard';
import PriceCard from '../Reuseables/PriceCard/PriceCard';
import UpcomingPricesCard from '../Reuseables/UpcomingPricesCard/UpcomingPricesCard';
import moment from 'moment';
import { transitTimeCalculator } from 'util/RateSheet/rateSheet';
import { CARGO_CATEGORIES } from 'util/constants';

const FullContainerLoad = ({
  route_id,
  run,
  setRun,
  transportMode,
  isMakeOffer = false,
  makeOfferCallBack = () => {}
}) => {
  const auth = useSelector((state) => state.session.auth);

  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [previewRateSheet, setPreviewRateSheet] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const toggleBreakdown = (bool) => {
    setIsOpen(bool);
  };

  const toggleBreakdownEdit = (bool) => {
    setIsOpenEdit(bool);
  };

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const togglePreview = (data) => {
    if (!isPreviewOpen) {
      setPreviewRateSheet(data);
    }
    setIsPreviewOpen((state) => !state);
  };

  const [searchKeyWord, setSearchKeyWord] = useState('');

  useEffect(() => {
    setLoading(true);
    const data_to_send = {
      admin_id: auth.accountId,
      route_id: route_id,
      load_type: CARGO_CATEGORIES.FCL.label,
      searchKeyWord: searchKeyWord
    };
    RateSheetService.fetchRateSheetPriceInfo(data_to_send, auth.token).then(
      (res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          setRates(res);
        }
        setLoading(false);
      }
    );
  }, [run, searchKeyWord]);

  const [viewPricePopUpOpen, setViewPricePopUpOpen] = useState(false);
  const [viewPriceBtnClickData, setViewPriceBtnClick] = useState();

  const handleViewPrices = (details) => {
    setViewPriceBtnClick(details);
    setViewPricePopUpOpen(true);
  };

  const [viewType, setViewType] = useState(isMakeOffer ? 'List' : 'Grid');
  const [openMoreOptions, setOpenMoreOptions] = useState(false);
  const [moreOptionClicked, setMoreOptionClicked] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const TableHeading = [
    'Carrier',
    'Container Name',
    'Starting Price',
    'Effective Date',
    'Expiry Date',
    'Status',
    'Port to Port Time',
    'Action'
  ];

  const Keys = [
    'carrier_id',
    'container_type',
    'price',
    'effective_date',
    'expiry_date',
    'status',
    'transit_time',
    '_id'
  ];

  const TableData = rates?.map((item) =>
    Keys.map((Key) => {
      if (Key === '_id') {
        return (
          <>
            <div className="position-relative">
              <center>
                <Tooltip title="View All Prices" arrow>
                  <Button
                    variant="text"
                    // startIcon={<VisibilityIcon />}
                    className={`btn-neutral-primary py-1 px-2`}
                    onClick={() => {
                      handleViewPrices(item);
                    }}>
                    View
                    {/* <VisibilityIcon /> */}
                    {/* View All Prices */}
                  </Button>
                </Tooltip>
              </center>
              {/* {moreOptionClicked?._id === item?._id &&
                        <SmallPopupWrapper open={openMoreOptions} onClose={() => setOpenMoreOptions(null)}>
                            <MenuItem onClick={() => { setOpenMoreOptions(null); }}>View</MenuItem>
                            <MenuItem onClick={() => setConfirmDialogOpen(true)} >Remove</MenuItem>
                        </SmallPopupWrapper>
                    } */}
            </div>
          </>
        );
      }
      if (Key === 'price') {
        return <>{`$${item?.price?.toLocaleString()}`}</>;
      }
      if (Key === 'effective_date' || Key === 'expiry_date') {
        return item[Key] !== 'NA' ? moment(item[Key]).format('ll') : 'NA';
      }
      if (Key === 'status') {
        let style =
          'm-0 px-3 py-0 h-auto text-success border-1 border-success rounded badge badge-neutral-success';
        if (item.status === 'Upcoming') {
          style =
            'm-0 px-3 py-0 h-auto text-warning border-1 border-warning rounded badge badge-neutral-warning';
        }
        return <p className={style}>{item.status}</p>;
      }
      if (Key === 'carrier_id') {
        return item?.carrier_id?.fullName;
      }
      if (Key === 'transit_time') {
        return transitTimeCalculator(item.transit_time.port_to_port);
      } else {
        return item[Key] || '';
      }
    })
  );

  const GridViewHeadings = ['Carrier', '20FT', '40FT', '40FT HC', '45FT HC'];

  const GridViewKey = ['carrier_id', '20FT', '40FT', '40FT HC', '45FT HC'];
  const outputArray = [];

  rates.forEach((item) => {
    const existingEntry = outputArray.find(
      (entry) => entry.carrier_id._id === item.carrier_id._id
    );

    if (existingEntry) {
      existingEntry[item.container_type] = item;
    } else {
      const newEntry = {
        carrier_id: item.carrier_id,
        '20FT': false,
        '40FT': false,
        '40FT HC': false,
        '45FT HC': false
      };
      newEntry[item.container_type] = item;
      outputArray.push(newEntry);
    }
  });

  const GridData = outputArray.map((item) =>
    GridViewKey.map((Key) => {
      if (Key === 'carrier_id') {
        return (
          <ShippingLineCard
            logo={item?.carrier_id?.logo?.url}
            name={item?.carrier_id?.fullName}
          />
        );
      }
      if (item[Key]) {
        if (item[Key].status === 'Active') {
          return (
            <PriceCard
              item={item[Key]}
              handleViewPrices={() => {
                handleViewPrices(item[Key]);
              }}
            />
          );
        } else {
          return (
            <UpcomingPricesCard
              handleViewPrices={() => {
                handleViewPrices(item[Key]);
              }}
            />
          );
        }
      } else {
        return <center> No Rates Avialable</center>;
      }
    })
  );

  return (
    <>
      {/* {loading && <LoaderComponent loading={loading} />} */}

      {viewType === 'List' ? (
        <TableWithSearchBar
          animatedSearchbar={{
            show: true,
            value: searchKeyWord,
            onChange: (value) => setSearchKeyWord(value),
            setLoading: setLoading
          }}
          loading={loading}
          TableHeading={TableHeading}
          TableBody={TableData}
          TableGrid={'repeat(6,1fr) 1.3fr 0.7fr'}
          actionButtons={
            isMakeOffer
              ? [
                  <Button
                    variant="text"
                    onClick={() => setRun(!run)}
                    className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                    <CachedIcon className="w-50" />
                  </Button>
                ]
              : [
                  <div className="d-flex align-items-center">
                    <ButtonGroup variant="text">
                      <Tooltip
                        title="List View"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <Button
                          size="small"
                          className={clsx(
                            'btn-dark btn-transition-none d-30 p-0 btn-icon btn-animated-icon-sm',
                            {
                              active: viewType === 'List',
                              'bg-kargo-blue': viewType === 'List'
                            }
                          )}
                          onClick={() => {
                            setViewType('List');
                          }}>
                          <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                            <ViewCompactTwoToneIcon fontSize="small" />
                          </span>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title="Grid View"
                        arrow
                        classes={{ tooltip: 'tooltip-kargo-blue' }}>
                        <Button
                          size="small"
                          className={clsx(
                            'btn-dark btn-transition-none d-30 p-0 btn-icon btn-animated-icon-sm',
                            {
                              active: viewType === 'Grid',
                              'bg-kargo-blue': viewType === 'Grid'
                            }
                          )}
                          onClick={() => {
                            setViewType('Grid');
                          }}>
                          <span className="btn-wrapper--icon  d-flex align-items-center justify-content-center">
                            <GridOnTwoToneIcon fontSize="small" />
                          </span>
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </div>,
                  <Button
                    variant="text"
                    onClick={() => setRun(!run)}
                    className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
                    <CachedIcon className="w-50" />
                  </Button>
                ]
          }
        />
      ) : (
        <GridViewRateSheet
          animatedSearchbar={{
            show: true,
            value: searchKeyWord,
            onChange: (value) => setSearchKeyWord(value),
            setLoading: setLoading
          }}
          GridViewHeadings={GridViewHeadings}
          GridData={GridData}
          loading={loading}
          actionButtons={[
            <div className="d-flex align-items-center">
              <ButtonGroup variant="text">
                <Tooltip
                  title="List View"
                  arrow
                  classes={{ tooltip: 'tooltip-kargo-blue' }}>
                  <Button
                    size="small"
                    className={clsx(
                      'btn-dark btn-transition-none d-30 p-0 btn-icon btn-animated-icon-sm',
                      {
                        active: viewType === 'List',
                        'bg-kargo-blue': viewType === 'List'
                      }
                    )}
                    onClick={() => {
                      setViewType('List');
                    }}>
                    <span className="btn-wrapper--icon d-20 d-flex align-items-center justify-content-center">
                      <ViewCompactTwoToneIcon fontSize="small" />
                    </span>
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Grid View"
                  arrow
                  classes={{ tooltip: 'tooltip-kargo-blue' }}>
                  <Button
                    size="small"
                    className={clsx(
                      'btn-dark btn-transition-none d-30 p-0 btn-icon btn-animated-icon-sm',
                      {
                        active: viewType === 'Grid',
                        'bg-kargo-blue': viewType === 'Grid'
                      }
                    )}
                    onClick={() => {
                      setViewType('Grid');
                    }}>
                    <span className="btn-wrapper--icon  d-flex align-items-center justify-content-center">
                      <GridOnTwoToneIcon fontSize="small" />
                    </span>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>,
            <Button
              variant="text"
              onClick={() => setRun(!run)}
              className={`btn-outline-primary d-flex align-items-center justify-content-center d-40 mr-2 p-0 rounded-pill`}>
              <CachedIcon className="w-50" />
            </Button>
          ]}
        />
      )}

      {viewPricePopUpOpen && (
        <ViewAllPrices
          open={viewPricePopUpOpen}
          handleClose={() => setViewPricePopUpOpen(false)}
          data={viewPriceBtnClickData}
          run={run}
          setRun={setRun}
          loadType={CARGO_CATEGORIES.FCL.label}
          isMakeOffer={isMakeOffer}
          makeOfferCallBack={(data) => {
            setViewPricePopUpOpen(false);
            makeOfferCallBack(data);
          }}
        />
      )}

      {/* Preview Rate */}
      <ModalPopupWrapper maxWidth={'lg'} noStyle open={isPreviewOpen}>
        {previewRateSheet.length > 0 && (
          <Preview
            data={previewRateSheet}
            open={isPreviewOpen}
            togglePreview={togglePreview}
            transportMode={transportMode}
            loadType={CARGO_CATEGORIES.FCL.label}
          />
        )}
      </ModalPopupWrapper>

      {/* {isOpenEdit &&
                <ViewBreakdown
                    run={run}
                    setRun={setRun}
                    method={"edit"}
                    data={data}
                    data_id={data_id}
                    name={name}
                    open={isOpenEdit}
                    handleClose={() => toggleBreakdownEdit(false)}
                />
            } */}
    </>
  );
};

export default FullContainerLoad;
