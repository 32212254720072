import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ConstantValuesService from 'service/ConstantValuesService';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField, InputLabel } from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  dropdown: {
    position: 'relative',
    width: '100%'
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px',
    margin: '8px 14px 0'
  },
  errorBorder: {
    border: '1px solid red'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

/**
 * CitySelect Component
 *
 * This component renders a dropdown for selecting cities based on a given state.
 * It fetches city options dynamically from a service when the `state` prop changes
 * and ensures that invalid selections are reset automatically.
 *
 * @component
 *
 * @prop {object} classes - Styles injected by withStyles HOC.
 * @prop {string} state - The state code to fetch cities for. (e.g., "CA-QC")
 * @prop {object|null} value - The selected city object. Must match the format of the options.
 * @prop {function} handleChange - Callback function invoked when the selected value changes.
 * @prop {boolean} showError - Whether to display an error message.
 * @prop {string} error - The error message to display.
 * @prop {boolean} required - Whether the field is required.
 * @prop {function} onBlur - Callback function invoked when the field loses focus.
 * @prop {string} placeholder - Placeholder text for the input field.
 */
const CitySelect = ({
  classes,
  state,
  value,
  handleChange,
  showError,
  error,
  required,
  onBlur,
  placeholder
}) => {
  const [options, setOptions] = useState([]); // Stores city options fetched from the service
  const [loading, setLoading] = useState(false); // Indicates whether city data is being fetched
  const [selectedValue, setSelectedValue] = useState(value || null); // Tracks the currently selected city

  /**
   * Effect to fetch city options whenever the `state` prop changes.
   * Resets the selected value if the `state` is null or options are invalid.
   */
  useEffect(() => {
    console.log('Inside CitySelect useFX');
    if (state) {
      setLoading(true);
      ConstantValuesService.fetchCityList(state, '')
        .then((res) => {
          if (res.error) {
            console.error('Error fetching cities:', res.error);
          } else {
            setOptions(res);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setOptions([]);
      setSelectedValue(null);
      handleChange(null, ''); // Notify parent component about the reset
    }
  }, [state]);

  /**
   * Effect to validate the current selection whenever the `options` change.
   * Resets the selected value if it is no longer valid.
   */
  // useEffect(() => {
  //   if (!options.some((option) => option.code === selectedValue?.code)) {
  //     setSelectedValue(null);
  //     // handleChange(null, ''); // Notify parent component about the invalid selection
  //   }
  // }, [options, selectedValue]);

  /**
   * Sync the local `selectedValue` state with the external `value` prop.
   */
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  /**
   * Handles changes to the selected city.
   *
   * @param {object} event - The event object from the input.
   * @param {object|null} newValue - The newly selected city object.
   */
  const handleSelectionChange = (event, newValue) => {
    setSelectedValue(newValue);
    handleChange(event, newValue);
  };

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={classes.root}>
        <InputLabel htmlFor={'Select City*'}></InputLabel>
        <Autocomplete
          style={{ width: '100%' }}
          size="small"
          value={selectedValue}
          id="combo-box-demo"
          options={options}
          // closeIcon={false}
          onChange={handleSelectionChange}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name={'City'}
              label={placeholder}
              required={required}
              variant={'outlined'}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}
            />
          )}
          // renderInput={(params) => <TextField {...params} label="Select City*" />}
        />
        {showError && error && (
          <FormHelperText variant="outlined" error>
            {error}
          </FormHelperText>
        )}
      </div>
      {/* <Select
                style={{ width: "200px" }}
                value={value}
                options={options}
                onChange={handleChange}
                placeholder={"Select City"}
            /> */}
    </>
  );
};

// PropTypes for validation
CitySelect.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string
};

// Default props
CitySelect.defaultProps = {
  state: '',
  value: '',
  showError: false,
  error: '',
  onBlur: () => {},
  required: false,
  placeholder: 'Select City'
};

export default compose(withStyles(styles))(CitySelect);
