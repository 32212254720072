import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import {
  FormHelperText,
  TextField,
  InputLabel,
  Tooltip
} from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import ConstantValuesService from 'service/ConstantValuesService';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  dropdown: {
    position: 'relative',
    width: '100%'
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px',
    margin: '8px 14px 0'
  },
  errorBorder: {
    border: '1px solid red'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

/**
 * PortCodeSelect Component
 *
 * A dropdown component for selecting a port code based on the selected city and transport mode.
 * The options are dynamically fetched when the `city` prop changes.
 *
 * Props:
 * - `classes` (object): CSS classes for styling the component.
 * - `city` (string): The selected city code. Triggers fetching of port codes.
 * - `transportMode` (string): The selected transport mode (e.g., SEA, AIR).
 * - `value` (object | string): The currently selected port code.
 * - `handleChange` (function): Callback function triggered when a new port is selected.
 * - `showError` (boolean): Whether to display an error message below the component.
 * - `error` (string): The error message to display when `showError` is `true`.
 * - `onBlur` (function): Callback triggered when the input field loses focus.
 * - `required` (boolean): Whether the field is required.
 *
 * Example Usage:
 * <PortCodeSelect
 *   classes={{ root: 'custom-class' }}
 *   city="NYC"
 *   transportMode="SEA"
 *   value={selectedPort}
 *   handleChange={(e, newPort) => console.log(newPort)}
 *   showError={hasError}
 *   error="Port is required"
 *   onBlur={() => console.log('Input blurred')}
 *   required
 * />
 */

const PortCodeSelect = ({
  classes,
  city,
  transportMode,
  value,
  handleChange,
  showError,
  error,
  onBlur,
  required
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  /**
   * Fetch port codes whenever the `city` prop changes.
   * If no city is selected, reset options and selected value.
   */
  useEffect(() => {
    if (city) {
      // When a city is selected, fetch port codes.
      setLoading(true);
      ConstantValuesService.fetchPortCodes(city, '', transportMode)
        .then((res) => {
          if (res.error) {
            console.error('Error fetching port codes:', res.error);
          } else {
            setOptions(res); // Populate options with the fetched results.
          }
        })
        .finally(() => setLoading(false)); // Stop the loader once the fetch is complete.
    } else {
      // Reset state when city is not selected.
      setOptions([]); // Clear options.
      setSelectedValue(''); // Reset selected value.
      handleChange(null, ''); // Notify parent component about the reset.
    }
  }, [city, transportMode]); // Dependencies to trigger this effect.

  /**
   * Sync the local `selectedValue` state with the external `value` prop.
   */
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  /**
   * Handles the selection of a new port from the dropdown.
   *
   * @param {object} event - The event triggered by the selection.
   * @param {object} newValue - The newly selected port object.
   */
  const handlePortChange = (event, newValue) => {
    setSelectedValue(newValue); // Update local state
    handleChange(event, newValue); // Notify parent about the change
  };

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={classes.root}>
        <InputLabel htmlFor={'Select Port*'}></InputLabel>
        <Autocomplete
          style={{ width: '100%' }}
          size="small"
          value={selectedValue}
          id="combo-box-demo"
          options={options}
          onChange={handlePortChange}
          onBlur={onBlur}
          // closeIcon={false}
          renderOption={(option) => (
            <div
              className="d-flex flex-wrap align-items-center font-size-md"
              style={{ gap: '0.25rem' }}>
              <span className="font-weight-bold">{option.name}</span>
              {option.code && <span>|</span>}
              <span className="text-uppercase text-gray-700">
                {option.code}
              </span>
              {option.city && (
                <>
                  <span>|</span>
                  <span className="text-capitalize">{option.city}</span>
                </>
              )}
            </div>
          )}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              name={'Port'}
              label={'Select Port'}
              variant={'outlined'}
              required={required}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}
            />
          )}
        />
        {showError && error && (
          <FormHelperText variant="outlined" error>
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

// PropTypes for validation
PortCodeSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  city: PropTypes.string,
  transportMode: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleChange: PropTypes.func.isRequired,
  showError: PropTypes.bool,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool
};

// Default props
PortCodeSelect.defaultProps = {
  city: '',
  transportMode: '',
  value: '',
  showError: false,
  error: '',
  onBlur: () => {},
  required: false
};

export default compose(withStyles(styles))(PortCodeSelect);
