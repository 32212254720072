import { HttpService } from './index';
import { getBaseUrl } from 'config/config';

import { HttpMethods, httpService } from './global';

export default class ShipmentsService {
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  static async fetch_shipment(data, token) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `admin/shipment/fetch-shipment`;

    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchShipmentStatus(id) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-status?id=${id}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async fetch_shipment_documents(id, token) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-documents?id=${id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchNotes(id, type) {
    const url = `${getBaseUrl()}shippment/fetch-shippment-note?id=${id}&type=${type}`;
    const res = await fetch(url);
    const result = await res.json();
    return result;
  }

  static async fetch_shipment_overview(token) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `admin/shipment/fetch-shipment-overview`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  static fetchShipments(data, token) {
    const url = `shipment/a/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentOverviewDetail(data, token) {
    const url = `shipment/a/shipment-page-overview`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'overview',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentContainerDetail(data, token, method) {
    const url = `shipment/a/container-detail`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: method,
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentActivity(data, token) {
    const url = `shipment/a/activity`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'activity',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentNotes(data, token) {
    const url = `shipment/a/notes/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'notes',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static createShipmentNotes(data, token) {
    const url = `shipment/a/notes/create`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'notes',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentDocuments(data, token) {
    const url = `shipment/a/documents/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'attachment',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchShipmentPayments(data, token) {
    const url = `shipment/a/payment/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'payment',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static trackContainerByContainerId(data, token) {
    const url = `shipment/container/track`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'tracking',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static getTrackingStatusFromVizion(data, token) {
    const url = `shipment/get-conatiner-status-using-referenceId/${data?.shipmentId}/${data?.containerId}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipment_details',
      method: 'tracking',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static shipmentBasicInfo(data, token) {
    const url = `shipment/shipment-basic-info`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static updateImportantDates(data, token) {
    const url = `shipment/a/important-dates/post`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static allocateContainer(data, token) {
    const url = `shipment/a/allocate-container`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static initiateShipment(data, token) {
    const url = `shipment/a/initiate-shipment`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static updateContainerStatus(data, token) {
    const url = `shipment/a/update-container-status`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static assignForwarderToShipment(data, token) {
    const url = `shipment/a/assign-forwarder-to-shipment`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static cancelShipment(data, token) {
    const url = `shipment/a/cancel`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  // static startChatting(shipment_id) {
  //   const url = `shipment/chat?shipment_id=${shipment_id}`;
  //   return httpService(HttpMethods.GET, url, null, null)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => console.log(err));
  // }

  static fetchRequestAddon(data, token) {
    const url = `shipment/a/add-on/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'add_on',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static acceptRequestAddon(data, token) {
    const url = `shipment/a/add-on/accept`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'add_on',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static rejectRequestAddon(data, token) {
    const url = `shipment/a/add-on/reject`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'add_on',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  // static isEligileForDelivery(data, token) {
  //   const url = `shipment/a/delivery/check-eligibility`;
  //   return httpService(HttpMethods.POST, url, data, token)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => console.log(err));
  // }

  // static applyForDelivery(data, token) {
  //   const url = `shipment/a/delivery/create`;
  //   return httpService(HttpMethods.POST, url, data, token)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => console.log(err));
  // }

  static fetchDelivery(data, token) {
    const url = `shipment/a/delivery/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'delivery',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static acceptDelivery(data, token) {
    const url = `shipment/a/delivery/accept`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'delivery',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static rejectDelivery(data, token) {
    const url = `shipment/a/delivery/reject`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'delivery',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static uploadAdditionalAttachments(data, token) {
    const url = `shipment/a/upload/additional-attachments`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  // static contactSupoort(data) {
  //   const url = `shipment/contact/support`;
  //   return httpService(HttpMethods.POST, url, data, null)
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => console.log(err));
  // }

  static fetchOverviewPageAttachments(data, token) {
    const url = `shipment/a/overview/attachments/fetch`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipment_details',
      method: 'overview',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async fetchInprocessTransactionDetails(token, shipment_id) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `shipment/a/fetch-inprocess-transaction-details?shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipment_details',
      method: 'payment',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async updateInprocessTransactionStatus(token, data) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `shipment/a/update-inprocess-transaction-status`;
    return httpService(HttpMethods.PUT, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async getShipperDetails(token, shipment_id) {
    // &status=${status}&shipment_id=${shipment_id}
    const url = `shipment/a/get-shipper-details-using-shipmentId/${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static fetchContainersLocation(data) {
    const url = `shipment/getContainerLocations`;
    return httpService(HttpMethods.POST, url, data, null)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static updateEarningConfirmationDate(data, token) {
    const url = `shipment/a/earning-comfirmation-date/update`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static checkEarningConfirmationDate(shipment_id, token) {
    const url = `shipment/a/earning-comfirmation-date/check?shipment_id=${shipment_id}`;
    return httpService(HttpMethods.GET, url, null, {
      token,
      type: 'shipments',
      method: 'edit',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
