import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Style from './CreateCarrierVessels.module.css';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';

const CreateCarrierVessels = ({
  open,
  run,
  setRun,
  onClose,
  edit = false,
  editData,
  handleEdit,
  handleAdd,
  data,
  setData,
  error,
  setEditData,
  checkTransportationUnitType
}) => {
  const auth = useSelector((state) => state.session.auth);
  const transportLineInfo = useSelector(
    (state) => state.transportUnitNames.transport_lines_info
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //   const [error, setError] = useState();

  useEffect(() => {
    if (editData) {
      setData({
        ...data,
        name: editData?.name,
        short_name: editData?.short_name,
        number: editData?.number
      });
    } else {
      setData({
        ...data,
        name: '',
        short_name: '',
        number: ''
      });
    }
  }, [editData, open]);

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <ModalSimple
        modalTitle={`Add ${
          transportLineInfo?.type === 'Air' ? 'Air-craft' : 'Vessel'
        }`}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={() => {
          onClose();
          setEditData(null);
        }}
        actionButtonText={editData ? 'Edit' : 'Create'}>
        <div className={Style.container}>
          <StyledInput
            margin="0"
            label="Enter Name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
          />
          <StyledInput
            margin="0"
            label="Enter Short Name"
            value={data.short_name}
            onChange={(e) => setData({ ...data, short_name: e.target.value })}
          />
          <StyledInput
            margin="0"
            label="Enter Vehicle Number"
            value={data.number}
            onChange={(e) => setData({ ...data, number: e.target.value })}
          />
          {error && (
            <>
              <span style={{ fontSize: '12px', color: 'red' }}>{error}</span>
            </>
          )}
        </div>
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={!editData ? handleAdd : handleEdit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={
          editData
            ? `Are you sure to edit this ${checkTransportationUnitType()}`
            : `Are you sure to add this ${checkTransportationUnitType()}`
        }
      />
    </>
  );
};

export default CreateCarrierVessels;
