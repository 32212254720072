import { useState, useEffect, useReducer, useMemo, useRef } from 'react';
import mediaQuery from 'css-mediaquery';
import { useTheme } from '../styles/themeContext';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * useForm hook - simplifying form state in components
 * @param {Object[]} fields
 * @param {string} fields[].name
 * @param {*} [fields[].initialValue]
 * @param {Boolean} [fields[].isRequired]
 * @param {Function} [fields[].validate]
 * @param {Object} [options]
 * @param {Function} [options.customReducer]
 * @param {Function} [options.customInit]
 * @param {Number} [options.debounceValidation]
 * @returns {Array}
 */
export function useForm(fields, options = {}) {
  const { customReducer, customInit, debounceValidation = 0 } = options;
  const [isValid, setIsValid] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const getValidationsMap = (fieldsArr) => {
    const map = new Map();
    fieldsArr.forEach((field) => {
      const { name, isRequired, validate } = field;
      let validationFunc = validate;

      if (isRequired && validate === undefined) {
        validationFunc = (value) => {
          const pass = value != null && value !== '';
          return {
            pass,
            errorMessage: 'This field is required.'
          };
        };
      }
      if (validationFunc) {
        map.set(name, validationFunc);
      }
    });
    return map;
  };

  const validationsMap = useMemo(() => getValidationsMap(fields), [fields]);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_FIELD': {
        const { name, value } = action;
        return { ...state, [name]: value };
      }
      default:
        throw new Error('invalid action type for reducer');
    }
  };

  const init = (fieldsArr) => {
    const state = {};
    fieldsArr.forEach((field) => {
      const { name, initialValue } = field;
      state[name] = initialValue !== undefined ? initialValue : '';
    });
    return state;
  };

  const [formValues, valuesDispatch] = useReducer(
    customReducer || reducer,
    fields,
    customInit || init
  );

  const debouncedFormValues = useDebounce(formValues, debounceValidation);

  const validateField = async (fieldName, fieldValue) => {
    const validationFunc = validationsMap.get(fieldName);

    if (!validationFunc) return { pass: true };

    try {
      // Handle both sync and async validation
      const result = await (validationFunc instanceof Promise
        ? validationFunc(fieldValue, formValues)
        : validationFunc(fieldValue, formValues));

      return result;
    } catch (error) {
      return {
        pass: false,
        errorMessage: 'Validation error occurred'
      };
    }
  };

  // useEffect(() => {
  //   const errors = {};
  //   if (validationsMap.size > 0) {
  //     validationsMap.forEach((validationFunc, field) => {
  //       const value = debouncedFormValues[field];

  //       const { pass, errorMessage } = validationFunc(
  //         value,
  //         debouncedFormValues
  //       );
  //       if (!pass) {
  //         errors[field] = errorMessage;
  //       }
  //     });
  //     setIsValid(Object.keys(errors).length === 0);
  //     setFormErrors(errors);
  //   }
  // }, [debouncedFormValues, validationsMap]);
  useEffect(() => {
    const performValidation = async () => {
      const errors = {};

      if (validationsMap.size > 0) {
        for (const [field, validationFunc] of validationsMap.entries()) {
          const value = debouncedFormValues[field];

          try {
            const { pass, errorMessage } = await (validationFunc instanceof
            Promise
              ? validationFunc(value, debouncedFormValues)
              : validationFunc(value, debouncedFormValues));

            if (!pass) {
              errors[field] = errorMessage;
            }
          } catch (error) {
            errors[field] = 'Validation error occurred';
          }
        }
      }

      setIsValid(Object.keys(errors).length === 0);
      setFormErrors(errors);
    };

    performValidation();
  }, [debouncedFormValues, validationsMap]);

  return [formValues, valuesDispatch, isValid, formErrors];
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const ssrEstimateWidth = (deviceType) => {
  if (process.env.BROWSER) {
    return window.innerWidth;
  }
  if (deviceType === 'phone') {
    return 400;
  }
  if (deviceType === 'tablet') {
    return 768;
  }
  return 1200;
};

let hydrationCompleted = false;

export function useMedia(queries, values = [true], defaultValue = false) {
  const themeContext = useTheme();
  const cleanedQueries = queries.map((q) => q.replace('@media ', ''));

  const [value, setValue] = useState(() => {
    let matchList;
    if (hydrationCompleted) {
      matchList = cleanedQueries.map((q) => window.matchMedia(q).matches);
    } else {
      const options = { width: ssrEstimateWidth(themeContext.device) };
      matchList = cleanedQueries.map((q) => mediaQuery.match(q, options));
    }
    const index = matchList.findIndex((mql) => mql);
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  });

  useEffect(() => {
    hydrationCompleted = true;

    const mediaQueryLists = cleanedQueries.map((q) => window.matchMedia(q));

    const handler = () => {
      const index = mediaQueryLists.findIndex((mql) => mql.matches);
      setValue(
        typeof values[index] !== 'undefined' ? values[index] : defaultValue
      );
    };
    handler();

    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [cleanedQueries, defaultValue, values]);
  console.log('useMedia', value);
  return value;
}

export const useHandleDropdownMenuClick = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      document.removeEventListener('click', toggleMenu);
    }
  };

  const openMenu = (event) => {
    event.preventDefault();
    setIsMenuOpen(!isMenuOpen);
    document.addEventListener('click', toggleMenu);
  };

  return { dropdownRef, isMenuOpen, openMenu };
};

const PREFERS_REDUCED_MOTION_QUERY = '(prefers-reduced-motion: no-preference)';

const isRenderingOnServer = typeof window === 'undefined';

const getInitialState = () => {
  return isRenderingOnServer
    ? true
    : !window.matchMedia(PREFERS_REDUCED_MOTION_QUERY).matches;
};

export const usePrefersReducedMotion = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] =
    useState(getInitialState);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(PREFERS_REDUCED_MOTION_QUERY);
    const listener = (event) => {
      setPrefersReducedMotion(!event.matches);
    };
    mediaQueryList.addListener(listener);
    return () => {
      mediaQueryList.removeListener(listener);
    };
  }, []);
  return prefersReducedMotion;
};

export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? '16px' : '18px');
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  });

  return fontSize;
}
