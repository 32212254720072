import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export default function DynamicTitle({ platform = 'console' }) {
  const location = useLocation();

  const platformTemplate = `- Kargoplex ${
    platform.charAt(0).toUpperCase() + platform.slice(1)
  }`;

  const platformTitles = useMemo(
    () => ({
      console: {
        '/dashboard': `Dashboard ${platformTemplate}`,
        '/login': `Login ${platformTemplate}`,
        '/pending-users': `Pending Users ${platformTemplate}`,
        '/user-metrics': `User Metrics ${platformTemplate}`,
        '/shipper': `Shipper ${platformTemplate}`,
        '/forwarder': `Forwarder ${platformTemplate}`,
        '/shipments': `Shipments ${platformTemplate}`,
        '/articles': `Articles ${platformTemplate}`,
        '/career': `Career ${platformTemplate}`,
        '/document': `Document ${platformTemplate}`,
        '/subscriber-management': `Subscriber Management ${platformTemplate}`,
        '/team': `Team ${platformTemplate}`,
        '/my-tickets': `Tickets ${platformTemplate}`,
        '/billing': `Billing ${platformTemplate}`,
        '/settings': `Settings ${platformTemplate}`
      }
    }),
    [platformTemplate]
  );

  const currentTitle =
    platformTitles[platform]?.[location.pathname] ||
    `Kargoplex - Simplify Your Logistics`;

  useEffect(() => {
    document.title = currentTitle;
  }, [currentTitle]);

  return (
    <Helmet>
      <title>{currentTitle}</title>
    </Helmet>
  );
}
