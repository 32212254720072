export const SubSystemType = {
  TRACKING: 'TRK',
  RATE_SHEET: 'RAT',
  INSURANCE: 'INS',
  FOWARDER: 'FWD'
};

export const SubSystemTypeNames = {
  TRK: 'Tracking',
  RAT: 'Rate Sheet',
  INS: 'Insurance',
  FWD: 'Forwarder'
};

export const subSystemTypeArray = [
  { label: 'Tracking', value: SubSystemType.TRACKING },
  { label: 'Rate Sheet', value: SubSystemType.RATE_SHEET },
  { label: 'Insurance', value: SubSystemType.INSURANCE },
  { label: 'Forwarder', value: SubSystemType.FOWARDER }
];
