import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServiceManagementService from 'service/ServiceManagementService';
import { notify } from 'sites/shipper/components/Toast/toast';
import ConfirmDialog from '../../../components/atomic/ConfirmDialog/ConfirmDialog';
import Style from './AddServicePopup.module.css';
import { Button } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import { upload_to_s3 } from 'util/awsUploader';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import ImageUploader from '../../Blogs/ImageUploader/ImageUploader';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';

const AddServicePopup = ({
  open,
  run,
  setRun,
  onClose,
  edit,
  editData,
  handleEdit
}) => {
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.session.auth);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    serviceName: '',
    serviceDescription: '',
    default_price_currency: 'usd',
    default_price_amount: '',
    icon: ''
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (edit) {
      setData({
        ...data,
        serviceName: editData?.serviceName,
        serviceDescription: editData?.serviceDescription,
        default_price_currency: editData?.default_price?.currency,
        default_price_amount: editData?.default_price?.value,
        icon: editData.icon
      });
    } else {
      setData({
        ...data,
        serviceName: '',
        serviceDescription: '',
        default_price_currency: 'usd',
        default_price_amount: '',
        icon: ''
      });
    }
  }, [edit, open]);

  const handleSubmit = async () => {
    setLoading(true);
    const iconUrl =
      typeof data.icon === 'string'
        ? data.icon
        : await upload_to_s3(data.icon, 'service-icon', auth.token);
    const data_to_send = {
      serviceName: data.serviceName,
      serviceDescription: data.serviceDescription,
      default_price_currency: data.default_price_currency,
      default_price_amount: data.default_price_amount,
      icon: iconUrl
    };
    if (
      !data.serviceName ||
      !data.serviceDescription ||
      !data.default_price_currency ||
      !data.default_price_amount ||
      !iconUrl
    ) {
      setError('All fields are required');
      return;
    }
    if (edit) {
      ServiceManagementService.editService(
        auth.accountId,
        editData._id,
        data_to_send,
        auth.token
      ).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setRun(!run);
          notify(dispatch, 'success', res.message);
          setError('');
          setData({
            ...data,
            default_price_amount: '',
            serviceDescription: '',
            serviceName: ''
          });
          onClose();
        }
        setLoading(false);
      });
    } else {
      ServiceManagementService.createService(
        auth.accountId,
        data_to_send,
        auth.token
      ).then((res) => {
        if (res.error) {
          setError(res.error);
        } else {
          setRun(!run);
          notify(dispatch, 'success', res.message);
          setError('');
          setData({
            ...data,
            default_price_amount: '',
            serviceDescription: '',
            serviceName: ''
          });
          onClose();
        }
        setLoading(false);
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value
    });
  };
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <ModalSimple
        modalTitle={'Create Service'}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={(e) => {
          onClose();
          handleEdit(e, null);
        }}
        actionButtonText={edit ? 'Edit' : 'Create'}>
        <div className={Style.container}>
          <StyledInput
            margin="0"
            placeholder="Enter Service Name"
            value={data.serviceName}
            name="serviceName"
            onChange={handleChange}
          />
          <StyledInput
            margin="0"
            placeholder="Enter Service Description"
            value={data.serviceDescription}
            name="serviceDescription"
            onChange={handleChange}
          />
          <StyledInput
            margin="0"
            placeholder="Enter Default Price"
            type="number"
            value={data.default_price_amount}
            name="default_price_amount"
            onChange={handleChange}
          />
          <ImageUploader
            data={data}
            setData={setData}
            name="icon"
            label="upload image"
            imageClassName={Style.imageStyle}
          />
          {error && (
            <span style={{ fontSize: '12px', color: 'red' }}>{error}</span>
          )}
        </div>
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={handleSubmit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={
          edit
            ? `Are you sure to update service`
            : `Are you sure to add service`
        }
      />
    </>
  );
};

export default AddServicePopup;
