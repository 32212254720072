import React from 'react';
import Style from './RateSheetInfoRouteDetails.module.css';

const RateSheetInfoRouteDetails = ({ routeInfo }) => {
  return (
    <>
      <div className={Style.routeDetailContainer}>
        <div>
          <span className="font-size-md font-weight-bold">Route Details</span>
        </div>
        <div className={Style.routeInnerContainer}>
          <div className={Style.routeflexContainer}>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Origin Country Code :</p>{' '}
              <p className={Style.labelDescription}>
                {routeInfo?.origin?.country?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Origin State :</p>{' '}
              <p className={Style.labelDescription}>
                {routeInfo?.origin?.state?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Origin City : </p>
              <p className={Style.labelDescription}>
                {routeInfo?.origin?.city?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Origin Port Name :</p>
              <p className={Style.labelDescription}>
                {' '}
                {routeInfo?.origin?.port?.name} ({routeInfo?.origin?.port?.code}
                )
              </p>
            </div>
          </div>
          <div className={Style.routeflexContainer}>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Destination Country Code :</p>{' '}
              <p className={Style.labelDescription}>
                {routeInfo?.destination?.country?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Destination State :</p>{' '}
              <p className={Style.labelDescription}>
                {routeInfo?.destination?.state?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Destination City : </p>
              <p className={Style.labelDescription}>
                {routeInfo?.destination?.city?.name}
              </p>
            </div>
            <div className={Style.labelContainer}>
              <p className={Style.labelHeading}>Destination Port Name :</p>
              <p className={Style.labelDescription}>
                {' '}
                {routeInfo?.destination?.port?.name} (
                {routeInfo?.destination?.port?.code})
              </p>
            </div>
          </div>
        </div>
        <div className={`w-50 ${Style.labelContainer}`}>
          <p className={Style.labelHeading}>Transport Mode :</p>
          <p className={Style.labelDescription}> {routeInfo?.transportMode}</p>
        </div>
      </div>
    </>
  );
};

export default RateSheetInfoRouteDetails;
