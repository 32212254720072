import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Style from './CreateTrackingSources.module.css';
import ModalSimple from 'components/ModalSimple';
import StyledInput from 'components/StyledInput';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import ConfirmDialog from 'sites/shipper/components/atomic/ConfirmDialog/ConfirmDialog';
import { Switch } from '@material-ui/core';
import { SubSystemType } from 'util/subSystemType';
import { SourceManagmentService } from 'service';
import StyledSelect from 'components/StyledSelect';
const CreateTrackingSources = ({
  open,
  run,
  setRun,
  onClose,
  edit = false,
  editData,
  handleEdit,
  handleAdd,
  data,
  setData,
  error,
  setEditData
}) => {
  const auth = useSelector((state) => state.session.auth);
  const transportLineInfo = useSelector(
    (state) => state.transportUnitNames.transport_lines_info
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //   const [error, setError] = useState();

  useEffect(() => {
    if (editData) {
      setData({
        ...data,
        source: editData?.source?._id,
        track_container: editData?.track_container,
        track_mbl: editData?.track_mbl,
        tracking_booking: editData?.tracking_booking
      });
    } else {
      setData({
        ...data,
        source: '',
        track_container: false,
        track_mbl: false,
        tracking_booking: false
      });
    }
  }, [editData, open]);

  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const [sources, setSources] = useState([]);

  useEffect(() => {
    const data_to_send = {
      type: SubSystemType.TRACKING,
      is_active: true
    };
    SourceManagmentService.fetchSource(
      data_to_send,
      auth.token,
      'carrier',
      'create'
    ).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        const array = [];
        res.data.map((item) => {
          array.push({
            label: item.name,
            value: item._id
          });
        });
        setSources(array);
      }
    });
  }, []);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <ModalSimple
        modalTitle={`Add Carrier Source`}
        open={open}
        action={() => setConfirmSubmit(true)}
        modalDescription={''}
        maxWidth={'xs'}
        fullWidth={true}
        handleClose={() => {
          onClose();
          setEditData(null);
        }}
        actionButtonText={editData ? 'Edit' : 'Create'}>
        <div className={Style.container}>
          {/* <StyledInput
            margin="0"
            label="Enter Source"
            value={data.source}
            onChange={(e) => setData({ ...data, source: e.target.value })}
          /> */}
          <StyledSelect
            options={sources}
            margin="0"
            label="Enter Source"
            value={data.source}
            onChange={(e) => setData({ ...data, source: e.target.value })}
          />
          <div className={`c-flex c-justify-content-between `}>
            <span>Track Container:</span>
            <Switch
              className="switch-small toggle-switch-primary"
              onChange={() =>
                setData((prev) => {
                  return {
                    ...prev,
                    track_container: !prev.track_container
                  };
                })
              }
              checked={data.track_container}
              name="track_container"
            />
          </div>
          <div className={`c-flex c-justify-content-between `}>
            <span>Track MBL:</span>
            <Switch
              className="switch-small toggle-switch-primary"
              onChange={() =>
                setData((prev) => {
                  return {
                    ...prev,
                    track_mbl: !prev.track_mbl
                  };
                })
              }
              checked={data.track_mbl}
              name="track_mbl"
            />
          </div>{' '}
          <div className={`c-flex c-justify-content-between `}>
            <span>Track Booking:</span>
            <Switch
              className="switch-small toggle-switch-primary"
              onChange={() =>
                setData((prev) => {
                  return {
                    ...prev,
                    tracking_booking: !prev.tracking_booking
                  };
                })
              }
              checked={data.tracking_booking}
              name="tracking_booking"
            />
          </div>
          {error && (
            <>
              <span style={{ fontSize: '12px', color: 'red' }}>{error}</span>
            </>
          )}
        </div>
      </ModalSimple>

      <ConfirmDialog
        confirmDialogFunction={!editData ? handleAdd : handleEdit}
        confirmDialogOpen={confirmSubmit}
        setConfirmDialogOpen={setConfirmSubmit}
        question={
          editData
            ? `Are you sure to edit this Carrier Source`
            : `Are you sure to add this Carrier Source`
        }
      />
    </>
  );
};

export default CreateTrackingSources;
