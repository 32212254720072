import React from 'react';
import Style from './TopText.module.css';
import LogoLight from 'assets/logos/kargo-04-light.svg';
import LogoDark from 'assets/logos/kargo-04-dark.svg';
const TopText = ({ dark = false }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div>
          <img
            src={dark ? LogoDark : LogoLight}
            width="200px"
            height="auto"
            alt="KARGOPLEX"
          />
        </div>

        {!dark && (
          <span className={Style.text_bold_big}>
            One tool for your whole team needs
          </span>
        )}
      </div>
    </>
  );
};

export default TopText;
