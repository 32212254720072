import StyledInput from 'components/StyledInput';
import React, { useState } from 'react';

export function LeadsManagementModalForm({ data, handleChange }) {
  return (
    <>
      <StyledInput
        name="email"
        label="Email Address"
        value={data.email}
        onChange={handleChange}
      />
    </>
  );
}
