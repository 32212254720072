import React, { useEffect } from 'react';
import { useState } from 'react';
import { AuthService } from 'service';
import Style from './EnterEmail.module.css';
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';

const EnterEmail = () => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [show, setShow] = useState(1);
  const [error, setError] = useState('');

  const handleSubmit = () => {
    AuthService.forgotPasswordMail(email).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        setShow(2);
      }
    });
  };

  useEffect(() => {
    if (show === 2) {
      setTimeout(() => {
        history.push('/login');
      }, 7000);
    }
  }, [show]);

  return (
    <>
      {/* <h3>Forgot Password</h3> */}
      {show === 1 && (
        <>
          <div
            className={Style.container}
            style={{
              backgroundImage: 'url(assets/authentication/login_back.png)'
            }}>
            <div>
              <div className={Style.form_container}>
                <div className={Style.form}>
                  <span className={Style.form_legend}>Forgot Password?</span>

                  <span className={Style.form_desc}>
                    Please enter your email address below, and we'll send you a
                    Reset Password link to regain access to your account
                  </span>

                  <FormControl variant="outlined" size="small">
                    <InputLabel htmlFor="email">Email address</InputLabel>
                    <OutlinedInput
                      className={Style.input}
                      id="email"
                      type="email"
                      label="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoFocus
                      autoComplete="email"
                    />
                  </FormControl>

                  <div className={Style.form_actions_container}>
                    {error && (
                      <p style={{ fontSize: '12px', color: 'red' }}>{error}</p>
                    )}
                    <Button
                      className={`${Style.signin_btn} btn-primary`}
                      onClick={handleSubmit}>
                      Confirm
                    </Button>
                  </div>
                </div>

                <Divider />

                <div className={Style.caption}>
                  Lost your way? No worries! We've got your back. Enter your
                  email, and we'll guide you back to the right path.
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {show === 2 && (
        <>
          <div
            className={Style.container}
            style={{
              backgroundImage: 'url(assets/authentication/login_back.png)',
              position: 'relative'
            }}>
            <div className={Style.backdrop}></div>
            <h3 style={{ zIndex: '2' }}>
              Reset password link has been sent to your mail.
            </h3>
          </div>
        </>
      )}
    </>
  );
};

export default EnterEmail;
