import { HttpMethods, httpService } from './global';

export default class LeadService {
  static async fetchLead(page, search, token, data = {}) {
    const url = `admin/shipment-notification?page=${page}&search=${search}`;
    return httpService(HttpMethods.GET, url, data, {
      token,
      type: 'shipper_notification',
      method: 'read',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async addUser(data, token) {
    const url = `admin/shipment-notification`;
    return httpService(HttpMethods.POST, url, data, {
      token,
      type: 'shipper_notification',
      method: 'create',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }

  static async removeUser(accountId, token, data = {}) {
    const url = `admin/shipment-notification/${accountId}`;
    return httpService(HttpMethods.DELETE, url, data, {
      token,
      type: 'shipper_notification',
      method: 'delete',
      platform: 'Admin'
    })
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(err));
  }
}
