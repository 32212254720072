
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate, isValidDate, maxDOB } from 'util/Utils';

const DatePicker = ({
  info,
  onUpdate,
  fieldsValidCallback,
  withErrors,
  showAllErrors,
  variant,
  formatted,
  inputVariant,
  disablePast,
  size,
  label,
  maxDate,
  minDate,
  margin,
  showLabel
}) => {
  const [open, setOpen] = useState(false);

  const getDummyDate = (date) => {
    let tempDate = new Date(Date.now())

    if (minDate) {
      tempDate = minDate
    }
    if (maxDate) {
      tempDate = maxDate
    }
    if (date) {
      tempDate = new Date(date)
    }
    return tempDate
  }

  const [selectedDate, setSelectedDate] = useState(
    // info ? new Date(info) : new Date(Date.now())
    // new Date(info)
    getDummyDate(info)
  );
  const handleDateChange = (date, isFinish) => {
    console.log('handleDateChange', date, isFinish);
    setSelectedDate(date);
  };

  const [isValid, setIsValid] = useState(true);
  const handleError = (error) => {
    console.log('ON Error-', error, '-', selectedDate);
    setIsValid(!error ? true : false);
  };

  useEffect(() => {
    console.log(
      'UseFX-',
      selectedDate,
      '-',
      isValid,
      '-',
      isValidDate(selectedDate)
    );
    fieldsValidCallback(isValid);
    if (isValidDate(selectedDate) && isValid) {
      console.log('calling onUpdate-', selectedDate, '-', isValid);
      onUpdate(formatted ? formatDate(selectedDate) : selectedDate);
    } else {
      onUpdate(null);
    }
  }, [selectedDate, isValid]);

  return (
    // <div className="d-flex align-items-center">
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        // disableToolbar
        // open={open}
        variant={variant}
        inputVariant={inputVariant}
        format="MM/dd/yyyy"
        placeholder='MM/DD/YYYY'
        margin={margin}
        size={size}
        id="date-picker-inline"
        label={showLabel ? label : null}
        disablePast={disablePast}
        fullWidth={true}
        value={selectedDate ?? null}
        onOpen={() => setOpen(true)}
        onAccept={() => setOpen(true)}
        onChange={handleDateChange}
        onError={handleError}
        minDate={minDate}
        maxDate={maxDate}
        InputLabelProps={{
          shrink: true,
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          color: 'primary',
        }}
      />
    </MuiPickersUtilsProvider>
    // </div>
  );
};

DatePicker.defaultProps = {
  // info: Date.now(),
  withErrors: true,
  onUpdate: () => { },
  fieldsValidCallback: () => { },
  variant: 'inline',
  formatted: false,
  inputVariant: 'standard',
  disablePast: false,
  label: "(mm/dd/yyyy)",
  size: "medium",
  margin: "normal",
  showLabel: true
};

DatePicker.propTypes = {
  info: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  onUpdate: PropTypes.func.isRequired,
  showAllErrors: PropTypes.bool,
  withErrors: PropTypes.bool,
  fieldsValidCallback: PropTypes.func,
  variant: PropTypes.oneOf(['inline', 'dialog', 'static']),
  inputVariant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  formatted: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  disablePast: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  margin: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ]),
  showLabel: PropTypes.bool
};

export default DatePicker;
