import { SAVE_ROUTE_INFO, SAVE_SELECTED_RATE } from '../actions';

const initialState = {
  route_info: null
};

export default function rateSheet(state = initialState, action) {
  switch (action.type) {
    case SAVE_ROUTE_INFO:
      return {
        ...state,
        route_info: action.payload
      };
    default:
      return state;
  }
}
