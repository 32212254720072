import { IconButton } from '@mui/material';
import Style from './Table.module.css';
import TableRow from './TableRow/TableRow';
import { Button, Divider } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { Input } from 'antd';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmDialog from '../../../../../../components/atomic/ConfirmDialog/ConfirmDialog';
import {
  TRANSPORT_MODES,
  TRANSPORT_MODES_LIST,
  CARGO_CATEGORIES
} from 'util/constants';
import {
  chargeDetails,
  findLowestPriceItem,
  sectionName
} from 'sites/shipper/pages/ShipmentRates/RateSheetContent';

/* eslint-disable */

const Table = ({
  transportMode,
  pageData,
  method,
  data,
  idx,
  setBreakdownData,
  isSubmitDisable,
  setIsSubmitDisable,
  additionalData
}) => {
  const [check, setCheck] = useState(false);
  const [isEditTableName, setIsEditTableName] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // this calculate the sub total
  useEffect(() => {
    setIsSubmitDisable((prev) => {
      const updatedState = [...prev];
      updatedState[idx] = check;
      return updatedState;
    });
  }, [check]);

  const [subTotal, setSubTotal] = useState(0);
  // const [startingFrom, setStartingFrom] = useState(0)

  useEffect(() => {
    let sum = 0;
    // let startingFromSum = 0
    if (data && data.data) {
      let array = data.data;

      if (data.title === sectionName['Shipment Charges']) {
        if (transportMode === TRANSPORT_MODES.AIR.label) {
          array = findLowestPriceItem(data.data);
        }
        if (
          transportMode === TRANSPORT_MODES.SEA.label &&
          additionalData?.loadType === CARGO_CATEGORIES.RORO.label
        ) {
          array = findLowestPriceItem(data.data);
        }
      }
      array.map((data) => (sum += Number(data.amount)));
      // array.map(data => startingFromSum += Number(data.amount))
    }
    setSubTotal(sum);
    // setStartingFrom(startingFromSum)
  }, [data]);

  // this adds the row to the table
  const addRow = (index) => {
    const rowData = {
      fee_code: null,
      fee_name: null,
      comment: null,
      // units: pageData ? pageData?.load.cargoDetails.quantity : 1,
      units: 1,
      unit_price: 0,
      amount: 0
    };

    setBreakdownData((previousData) => {
      const updatedPricebreakdown = [...previousData.price_breakdown];

      updatedPricebreakdown[index] = {
        ...updatedPricebreakdown[index],
        data: [...updatedPricebreakdown[index].data, rowData]
      };
      return {
        ...previousData,
        price_breakdown: updatedPricebreakdown
      };
    });
  };

  // this handles the row input values
  const handleRowValues = (e, tableIndex, rowIndex) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === 'units' || name === 'unit_price') {
      if (value < 0) {
        value = 0;
      }
    }

    setBreakdownData((previousData) => {
      const updatedPricebreakdown = [...previousData.price_breakdown];

      const updatedDataArray = [...updatedPricebreakdown[tableIndex].data];
      if (name === 'unit_price') {
        updatedDataArray[rowIndex] = {
          ...updatedDataArray[rowIndex],
          unit_price: value,
          amount: Number(updatedDataArray[rowIndex].units) * Number(value)
        };
      } else {
        updatedDataArray[rowIndex] = {
          ...updatedDataArray[rowIndex],
          [name]: value
        };
      }

      updatedPricebreakdown[tableIndex] = {
        ...updatedPricebreakdown[tableIndex],
        data: updatedDataArray
      };
      console.log(updatedPricebreakdown);
      return {
        ...previousData,
        price_breakdown: updatedPricebreakdown
      };
    });
  };
  // this handles the delete of row
  const handleRowDelete = (tableIndex, rowIndex) => {
    setBreakdownData((previousData) => {
      const updatedPricebreakdown = [...previousData.price_breakdown];

      updatedPricebreakdown[tableIndex] = {
        ...updatedPricebreakdown[tableIndex],
        data: updatedPricebreakdown[tableIndex].data.filter(
          (data, index) => index !== rowIndex
        )
      };
      return {
        ...previousData,
        price_breakdown: updatedPricebreakdown
      };
    });
  };

  // this handles the delete of table
  const handleTableDelete = (tableIndex) => {
    const newArray = isSubmitDisable.filter(
      (item, index) => index !== tableIndex
    );
    setIsSubmitDisable(newArray);

    setBreakdownData((previousData) => {
      return {
        ...previousData,
        price_breakdown: previousData.price_breakdown.filter(
          (data, idx) => idx !== tableIndex
        )
      };
    });
  };

  // this handles the edit of table name
  const handleEditTableName = (e, tableIndex) => {
    setBreakdownData((previousData) => {
      const updatedData = [...previousData.price_breakdown];

      updatedData[tableIndex] = {
        ...updatedData[tableIndex],
        title: e.target.value
      };

      return {
        ...previousData,
        price_breakdown: updatedData
      };
    });
  };

  // handle fee code select
  const handleRowSelectValues = (e, tableIndex, rowIndex, name) => {
    const value = e;

    setBreakdownData((previousData) => {
      const updatedPricebreakdown = [...previousData.price_breakdown];

      const updatedDataArray = [...updatedPricebreakdown[tableIndex].data];

      if (name === 'fee_code') {
        const fee_name = chargeDetails(
          transportMode,
          data?.title,
          additionalData
        ).filter((item) => item.feeCode === value)[0]?.feeName;
        updatedDataArray[rowIndex] = {
          ...updatedDataArray[rowIndex],
          fee_code: value,
          fee_name: fee_name
        };
      } else {
        updatedDataArray[rowIndex] = {
          ...updatedDataArray[rowIndex],
          [name]: value
        };
      }

      updatedPricebreakdown[tableIndex] = {
        ...updatedPricebreakdown[tableIndex],
        data: updatedDataArray
      };

      return {
        ...previousData,
        price_breakdown: updatedPricebreakdown
      };
    });
  };

  const renderPriceLabel = () => {
    let label = 'Subtotal';
    if (data.title === sectionName['Shipment Charges']) {
      if (transportMode === TRANSPORT_MODES.AIR.label) {
        label = 'Subtotal (Starting From)';
      }
      if (
        transportMode === TRANSPORT_MODES.SEA.label &&
        additionalData?.loadType === CARGO_CATEGORIES.RORO.label
      ) {
        label = 'Subtotal (Starting From)';
      }
    }
    return label;
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <div className={Style.container}>
        <div className={Style.head_container}>
          <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {isEditTableName ? (
              <>
                <Input
                  className={Style.editTableNameInput}
                  value={data.title}
                  onChange={(e) => handleEditTableName(e, idx)}
                />
                <IconButton
                  onClick={() => setIsEditTableName(false)}
                  className={Style.edit_table_btn}>
                  <CheckIcon sx={{ fontSize: '15px !important' }} />
                </IconButton>
              </>
            ) : (
              <>
                <span className={Style.title}>{data.title}</span>
                {/* <IconButton
                                    onClick={() => setIsEditTableName(true)}
                                    className={Style.edit_table_btn}
                                >
                                    <Edit sx={{ fontSize: '15px !important' }} />
                                </IconButton> */}
              </>
            )}
          </div>

          <div className={Style.btn_container}>
            <Button
              size="small"
              className={'btn-neutral-primary'}
              disabled={data?.data?.length > 0 && check}
              onClick={() => addRow(idx)}>
              Add Row
            </Button>
            {idx !== 0 && idx !== 1 && idx !== 2 && (
              <>
                <Button
                  variant="contained"
                  size="small"
                  className={Style.delete_btn}
                  onClick={() => {
                    setConfirmDialogOpen(true);
                  }}>
                  Delete
                </Button>
              </>
            )}
          </div>
        </div>

        <Divider />

        <div className={Style.table_header}>
          <div>Fee Code </div>
          <div>Fee Name </div>
          <div>Comment </div>
          <div>Units </div>
          <div>Unit Price </div>
          <div>Amount</div>
          <div>Action</div>
        </div>

        {data?.data?.map((item, index) => {
          return (
            <TableRow
              transportMode={transportMode}
              additionalData={additionalData}
              sectionName={data?.title}
              key={index}
              tableIndex={idx}
              rowIndex={index}
              data={item}
              allRows={data.data}
              handleRowValues={handleRowValues}
              handleRowSelectValues={handleRowSelectValues}
              setCheck={setCheck}
              handleRowDelete={handleRowDelete}
              method={method}
            />
          );
        })}

        <Divider />

        <div className={Style.sub_total_container}>
          <span className={Style.bold_bottom_table}>{renderPriceLabel()}</span>
          <span className={Style.bold_bottom_table}>USD</span>
          <span className={Style.sub_total_price}>{subTotal}</span>
        </div>

        {/* {(data.title === sectionName["Shipment Charges"] &&
                    (
                        (transportMode === TRANSPORT_MODES.AIR.label) ||
                        (transportMode === TRANSPORT_MODES.SEA.label && additionalData?.loadType === CARGO_CATEGORIES.RORO.label)
                    )) &&
                    <div className={`${Style.sub_total_container}  m-0`}>
                        <i className={`${Style.bold_bottom_table} font-size-sm`}>Starting from</i>
                        <i className={`${Style.bold_bottom_table} font-size-sm`}>USD</i>
                        <i className={`${Style.sub_total_price} font-size-sm`}>{startingFrom}</i>
                    </div>
                } */}

        <ConfirmDialog
          confirmDialogOpen={confirmDialogOpen}
          setConfirmDialogOpen={setConfirmDialogOpen}
          confirmDialogFunction={() => handleTableDelete(idx)}
          question={'Are you sure you want to delete this table?'}
        />
      </div>
    </div>
  );
};
/* eslint-disable */
export default Table;
