import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import Style from './PageTitleActions.module.css';
import RightDrawer from 'sites/shipper/components/layout/RightDrawer';

const PageTitleActions = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function openUserMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  const toggleDrawer = () => setShowDrawer(!showDrawer);

  return (
    <>
      <div className={`d-flex c-gap-1 flex-wrap justify-content-end`}>
        {/* <Button
          variant="contained"
          href="/freight/quote"
          className={`${Style.btn} btn-outline-primary shadow-none`}>
          <span className="btn-wrapper--label">Create Shipment</span>
        </Button> */}

        {/* <Button
          variant="contained"
          className={`${Style.btn} btn-primary shadow-none`}
          onClick={toggleDrawer}>
          <span className="btn-wrapper--icon">
            <FontAwesomeIcon icon={['fas', 'plus']} className="opacity-8" />
          </span>
          <span className="btn-wrapper--label">Shipper</span>
        </Button> */}
      </div>
      <RightDrawer
        onClose={toggleDrawer}
        open={showDrawer}
        widthClasses={Style.res_right_drawer}
        closeBtnClasses={Style.right_drawer_close_btn}
        toolTipTitle="close">
        <div className={Style.main}>
          <div
            className={`w-100 my-2 d-flex align-items-center ${Style.Header}`}></div>
        </div>
      </RightDrawer>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PageTitleActions);
