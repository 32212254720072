import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ConstantValuesService from 'service/ConstantValuesService';
import { Autocomplete } from '@material-ui/lab';
import { FormHelperText, TextField, InputLabel } from '@material-ui/core';
import LoaderComponent from 'sites/shipper/components/LoaderComponent';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  dropdown: {
    position: 'relative',
    width: '100%'
  },
  errorMsg: {
    color: '#f44336',
    fontSize: '12px',
    margin: '8px 14px 0'
  },
  errorBorder: {
    border: '1px solid red'
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    }
  }
});

/**
 * StateSelect Component
 *
 * A dropdown component to select a state or province based on the selected country.
 * This component fetches the states dynamically when the `country` prop changes
 * and ensures the selected value resets if the `country` prop changes.
 *
 * Props:
 * - `classes` (object): CSS classes for styling the component.
 * - `country` (string): The selected country code. When this changes, the state options are updated.
 * - `value` (object | string): The currently selected state. Should be an object with `name` and `code` properties or a string.
 * - `handleChange` (function): Callback function triggered when a new state is selected.
 * - `showError` (boolean): Whether to display an error message below the component.
 * - `error` (string): The error message to display when `showError` is `true`.
 * - `onBlur` (function): Callback triggered when the input field loses focus.
 * - `required` (boolean): Whether the field is required.
 *
 * Example Usage:
 * <StateSelect
 *   classes={{ root: 'custom-class' }}
 *   country="US"
 *   value={selectedState}
 *   handleChange={(e, newState) => console.log(newState)}
 *   showError={hasError}
 *   error="State is required"
 *   onBlur={() => console.log('Input blurred')}
 *   required
 * />
 */

const StateSelect = ({
  classes,
  country,
  value,
  handleChange,
  showError,
  error,
  onBlur,
  required
}) => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const [loading, setLoading] = useState(false);
  /**
   * Effect to fetch state options whenever the `country` prop changes.
   * If the `country` is undefined, resets the options and selected value.
   */
  useEffect(() => {
    if (country) {
      setLoading(true);
      ConstantValuesService.fetchStateList(country, '')
        .then((res) => {
          if (res.error) {
            console.error(res.error);
          } else {
            setOptions(res);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setOptions([]);
      setSelectedValue(''); // Reset selected value if the country is cleared
      handleChange(null, '');
    }
  }, [country]);

  /**
   * Effect to sync the local `selectedValue` state with the external `value` prop.
   */
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  /**
   * Handles the selection of a new state from the dropdown.
   *
   * @param {object} event - The event triggered by the selection.
   * @param {object} newValue - The newly selected state object.
   */
  const handleStateChange = (event, newValue) => {
    setSelectedValue(newValue); // Update local state
    handleChange(event, newValue); // Notify parent about the change
  };

  //   useEffect(() => {
  //     // console.log(country)
  //     if (country) {
  //       setLoading(true);
  //       ConstantValuesService.fetchStateList(country, '').then((res) => {
  //         if (res.error) {
  //           console.log(res.error);
  //         } else {
  //           // setStates(res)
  //           setOptions(res);
  //           setLoading(false);
  //         }
  //       });
  //     } else {
  //       setOptions([]);
  //     }
  //   }, [country]);

  return (
    <>
      {loading && <LoaderComponent loading={loading} />}
      <div className={classes.root}>
        <InputLabel htmlFor={'Select State*'}></InputLabel>
        <Autocomplete
          style={{ width: '100%' }}
          size="small"
          value={selectedValue}
          id="combo-box-demo"
          // closeIcon={false}
          options={options}
          onChange={handleStateChange}
          getOptionLabel={(option) => option.name}
          onBlur={onBlur}
          renderInput={(params) => (
            <TextField
              {...params}
              name={'state'}
              label={'Select State'}
              variant={'outlined'}
              required={required}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off' // disable autocomplete and autofill
              }}
            />
          )}
        />
        {showError && error && (
          <FormHelperText variant="outlined" error>
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

// export default StateSelect
export default compose(withStyles(styles))(StateSelect);
