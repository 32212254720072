import { useEffect, useState } from 'react';
import Style from './TableRow.module.css';
import { IconButton } from '@mui/material';
import { Button } from '@material-ui/core';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import { Input, Select, AutoComplete } from 'antd';
import { Divider } from '@material-ui/core';
import { chargeDetails } from 'sites/shipper/pages/ShipmentRates/RateSheetContent';
import DeleteIcon from '@mui/icons-material/Delete';
/* eslint-disable */

const TableRow = ({
  sectionName,
  allRows,
  additionalData,
  transportMode,
  method,
  data,
  setCheck,
  handleRowValues,
  rowIndex,
  tableIndex,
  handleRowDelete,
  handleRowSelectValues
}) => {
  const [isEditOn, setIsEditOn] = useState(true);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    if (method === 'edit') {
      if (!isEditOn) {
        // window.alert("additional data changed")
        setIsEditOn(true);
      }
    }
  }, [additionalData.loadType, additionalData.containerType]);

  useEffect(() => {
    if (method === 'edit') {
      setIsEditOn(false);
    }
  }, []);

  useEffect(() => {
    setCheck(isEditOn);
  }, [isEditOn]);

  // console.log(data)

  // this handle the fee_name from fee_code
  // useEffect(() => {
  // fee_name_list[data.fee_code];
  // let e = {
  //     target: {
  //         value: fee_name,
  //         name: 'fee_name'
  //     }
  // }

  // handleRowValues(e, tableIndex, rowIndex);
  // handleRowSelectValues(fee_name, tableIndex, rowIndex, "fee_name")

  // }, [data.fee_code])

  // this handles the amount
  // useEffect(() => {
  //     const amount = Number(data.units) * Number(data.unit_price);

  //     let e = {
  //         target: {
  //             value: amount,
  //             name: 'amount'
  //         }
  //     }

  //     handleRowValues(e, tableIndex, rowIndex);

  // }, [data.units, data.unit_price])

  return (
    <>
      <Divider />

      <div className={Style.container}>
        {!isEditOn ? (
          <>
            <div>{data.fee_code}</div>
            <div>{data.fee_name}</div>
            <div>{data.comment}</div>
            <div>{data.units}</div>
            <div>{data.unit_price}</div>
            <div>{data.amount}</div>

            <div className={Style.action_container}>
              <IconButton
                size="small"
                onClick={() => setIsEditOn(true)}
                className={`${Style.action_btn}`}>
                <EditIcon style={{ fontSize: '20px' }} />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => handleRowDelete(tableIndex, rowIndex)}
                className={`${Style.action_btn} ${Style.delete_btn} `}>
                {/* <RemoveIcon style={{ fontSize: '10px' }} /> */}
                <DeleteIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          </>
        ) : (
          <>
            <div className={Style.input_container}>
              {/* <Select
                                style={{ width: 120 }}
                                onChange={(e) => handleRowSelectValues(e, tableIndex, rowIndex, "fee_code")}
                                name="fee_code"
                                value={data.fee_code}
                                options={chargeDetails.map((item) => { return ({ value: item.feeCode, label: item.feeCode }) })}
                                className={Style.input}
                            /> */}
              <AutoComplete
                value={data.fee_code}
                options={chargeDetails(
                  transportMode,
                  sectionName,
                  additionalData
                )
                  .filter(
                    (row) =>
                      !allRows.some((item) => row.feeCode === item.fee_code)
                  )
                  .map((item) => {
                    return { value: item.feeCode, label: item.feeCode };
                  })}
                className={Style.input}
                onSelect={(value) => {
                  handleRowSelectValues(
                    value,
                    tableIndex,
                    rowIndex,
                    'fee_code'
                  );
                  handleRowSelectValues('', tableIndex, rowIndex, 'comment');
                }}
                placeholder="Select fee code"
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear
                autoFocus
                onClear={() => {
                  handleRowSelectValues('', tableIndex, rowIndex, 'fee_code');
                  handleRowSelectValues('', tableIndex, rowIndex, 'comment');
                }}
              />
            </div>
            <div className={Style.input_container}>
              <Input
                name="fee_name"
                value={data.fee_name}
                // onChange={(e) => handleRowValues(e, tableIndex, rowIndex)}
                required
                className={Style.input}
                readOnly
              />
            </div>
            <div className={Style.input_container}>
              <Select
                style={{ width: 120 }}
                onChange={(e) =>
                  handleRowSelectValues(e, tableIndex, rowIndex, 'comment')
                }
                options={
                  chargeDetails(transportMode, sectionName, additionalData)
                    .filter((item) => item.feeCode === data?.fee_code)[0]
                    ?.comment.map((item) => {
                      return { label: item, value: item };
                    }) || []
                }
                className={Style.input}
                name="comment"
                value={data.comment}
              />
            </div>
            <div className={Style.input_container}>
              <Input
                name="units"
                value={data.units}
                disabled
                type="number"
                required
                className={Style.input}
              />
              {/* <Input name="units" value={data.units} onChange={(e) => handleRowValues(e, tableIndex, rowIndex)} type="number" required className={Style.input} /> */}
            </div>
            <div className={Style.input_container}>
              <Input
                name="unit_price"
                onWheel={(e) => e.target.blur()}
                value={data.unit_price}
                onChange={(e) => handleRowValues(e, tableIndex, rowIndex)}
                type="number"
                required
                className={Style.input}
              />
            </div>
            <div className={Style.input_container}>
              <Input
                name="amount"
                value={data.amount}
                type="number"
                required
                className={Style.input}
              />
            </div>
            <div className={Style.action_container}>
              <Button
                variant="outlined"
                size="small"
                className={Style.save_btn}
                onClick={() => setIsEditOn(false)}
                disabled={
                  !data.fee_code ||
                  !data.comment ||
                  !data.units ||
                  !data.unit_price
                }>
                Done
              </Button>
              <IconButton
                size="small"
                onClick={(e) => {
                  setCheck(false);
                  handleRowDelete(tableIndex, rowIndex);
                }}
                className={`${Style.action_btn} ${Style.delete_btn}`}>
                <DeleteIcon style={{ fontSize: '20px' }} />
              </IconButton>
            </div>
          </>
        )}
      </div>
    </>
  );
};
/* eslint-disable */
export default TableRow;
