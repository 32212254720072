import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment';

import ModalSimple from 'components/ModalSimple';
import AnimatedSearchbar from 'components/Common/AnimatedSearchbar/AnimatedSearchbar';
import TableWithSearchBar from 'components/Tables/TableWithSearchBar';
import EmptyContainer from 'sites/shipper/components/EmptyContainer/EmptyContainer';
import StyledInput from 'components/StyledInput';

import AdminServices from 'service/AdminServices';

/**
 * AssignForwarderPopup Component
 *
 * This component allows the user to search, select, and assign a forwarder to a shipment or shipment rate.
 * It provides search functionality and pagination for ease of navigation.
 *
 * @component
 *
 * @param {object} props - Component props
 * @param {boolean} props.open - Controls modal visibility
 * @param {function} props.setOpen - Function to toggle modal visibility
 * @param {function} props.callBack - Callback function when a forwarder is selected
 */
const AssignForwarderPopup = ({ open, setOpen, callBack }) => {
  const auth = useSelector((state) => state.session.auth);

  // State variables
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [enterCompanyPopup, setEnterCompanyPopup] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const itemsPerPage = 15;

  /**
   * Handles page change for the table pagination
   * @param {object} event - Event object
   * @param {number} value - New page number
   */
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  /**
   * Fetches forwarders based on search keyword and page number
   */
  const fetchForwarders = async () => {
    setLoading(true);
    try {
      const response =
        await AdminServices.get_all_forwarders_kyc_verification_status(
          { keyword: search },
          auth.token,
          page,
          'forwarder',
          'read'
        );

      if (response.error) {
        setData([]);
        setDataCount(0);
      } else {
        console.log('Fetched forwarders', response);
        setData(response.data);
        setDataCount(response?.forwarderCount || 0);
      }
    } catch (error) {
      console.error('Error fetching forwarders:', error);
      setData([]);
      setDataCount(0);
    } finally {
      setLoading(false);
    }
  };

  // Effect to trigger fetching forwarders when `search` or `page` changes
  useEffect(() => {
    if (open) {
      fetchForwarders();
    }
  }, [search, page, open]);

  // Table headers and keys mapping
  const TableHeading = [
    'S.No.',
    'Company Name',
    'Owner Name',
    'Email',
    'Created On',
    'Action'
  ];

  const Keys = [
    'index',
    'company_name',
    'forwarder_name',
    'email',
    'createdAt',
    'id'
  ];

  // Processing table data
  const TableData = data?.map((item, index) =>
    Keys.map((Key) => {
      if (Key === 'index') return index + 1;
      if (Key === 'createdAt') return moment(item.createdAt).format('ll');
      if (Key === 'id') {
        return (
          <center key={item?.companyId}>
            <Button
              size="small"
              onClick={() => {
                callBack({
                  forwarderId: item?.id,
                  forwarderEmail: item?.email,
                  firstName: item?.firstName,
                  lastName: item?.lastName,
                  companyId: item?.companyId,
                  companyName: item?.company_name
                });
                setOpen(false);
              }}
              className="btn-primary">
              Select
            </Button>
          </center>
        );
      }
      return item[Key] || '';
    })
  );

  return (
    <>
      {/* Assign Forwarder Modal */}
      <ModalSimple
        modalTitle="Assign Forwarder"
        open={open}
        handleClose={() => setOpen(false)}
        fullWidth
        maxWidth="lg">
        <div className="d-flex flex-column" style={{ height: '65vh' }}>
          <div className="d-flex justify-content-between w-100">
            <AnimatedSearchbar
              placeholder="Search Forwarder by name, email, or owner name"
              value={search}
              setLoading={setLoading}
              onChange={setSearch}
            />
            <Button
              className="btn-primary"
              onClick={() => setEnterCompanyPopup(true)}>
              Add New Forwarder
            </Button>
          </div>

          {data && dataCount > 0 ? (
            <TableWithSearchBar
              animatedSearchbar={{ show: false }}
              tableMainHeading={`Search Results (${dataCount})`}
              loading={loading}
              TableHeading={TableHeading}
              TableBody={TableData}
              TableGrid="0.1fr repeat(5,1fr)"
              pagination={{
                count: Math.ceil(dataCount / itemsPerPage),
                page: page,
                onChange: handlePageChange
              }}
            />
          ) : (
            <div className="d-flex align-items-center h-100">
              <EmptyContainer
                title="Search for a Forwarder"
                description="Find a forwarder by name, email, or owner name to assign it to this rate."
              />
            </div>
          )}
        </div>
      </ModalSimple>

      {/* Enter Forwarder Name Modal */}
      <ModalSimple
        modalTitle="Enter Forwarder Name"
        open={enterCompanyPopup}
        fullWidth
        maxWidth="md"
        action={() => {
          callBack({
            forwarderId: null,
            forwarderEmail: null,
            firstName: null,
            lastName: null,
            companyId: null,
            companyName: companyName
          });
          setEnterCompanyPopup(false);
          setOpen(false);
        }}
        actionButtonText="Add"
        handleClose={() => setEnterCompanyPopup(false)}>
        <StyledInput
          label="Forwarder Name"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </ModalSimple>
    </>
  );
};

// Default Props
AssignForwarderPopup.defaultProps = {
  open: false,
  setOpen: () => {},
  callBack: () => {}
};

// PropTypes Validation
AssignForwarderPopup.propTypes = {
  open: PropTypes.bool.isRequired, // Controls modal visibility
  setOpen: PropTypes.func.isRequired, // Function to toggle modal visibility
  callBack: PropTypes.func.isRequired // Callback function when forwarder is selected
};

export default AssignForwarderPopup;
