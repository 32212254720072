import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { NavLink } from 'react-router-dom';
import Style from './Sidebar.module.css';
import { SettingsSidebarMenus } from 'sites/shipper/pages/Settings/Routes';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  AccountBalanceWalletOutlined,
  AttachFile,
  Dashboard,
  DescriptionOutlined,
  DirectionsBoatOutlined,
  FormatQuoteOutlined,
  InsertChartOutlined,
  MailOutlined,
  MoveToInboxOutlined,
  ReceiptOutlined,
  Settings,
  SettingsOutlined
} from '@material-ui/icons';
import Logo from 'assets/logos/kargo-04-light.svg';
import LogoIcon from 'assets/logos/kargo-04-light-icon.svg';
import { SIDEBAR_DRAWER_WIDTH } from 'util/constants';
import { withStyles } from '@material-ui/styles';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import AddBusinessTwoToneIcon from '@mui/icons-material/AddBusinessTwoTone';
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import SchoolIcon from '@mui/icons-material/School';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterDataInRedux } from 'actions';
import { userPermission } from 'util/adminPermissionRoles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PendingActionsTwoToneIcon from '@mui/icons-material/PendingActionsTwoTone';
// import Person2TwoToneIcon from '@mui/icons-material/Person2TwoTone';
// import TroubleshootTwoToneIcon from '@mui/icons-material/TroubleshootTwoTone';
import QueryStatsTwoToneIcon from '@mui/icons-material/QueryStatsTwoTone';
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone';
const screenWidth = window.innerWidth;

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    backgroundColor: '#06113C'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: SIDEBAR_DRAWER_WIDTH,
    width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: SIDEBAR_DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: SIDEBAR_DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
    minHeight: 74
    // necessary for content to be below app bar
    //...theme.mixins.toolbar
  },
  logoWrapper: {},
  logo: {
    height: '30px',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
});

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   drawerPaper: {
//     backgroundColor: '#06113C'
//   },
//   appBar: {
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },
//   appBarShift: {
//     marginLeft: DRAWER_WIDTH,
//     width: `calc(100% - ${DRAWER_WIDTH}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen
//     })
//   },
//   hide: {
//     display: 'none'
//   },
//   drawer: {
//     width: DRAWER_WIDTH,
//     flexShrink: 0,
//     whiteSpace: 'nowrap'
//   },
//   drawerOpen: {
//     width: DRAWER_WIDTH,
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen
//     })
//   },
//   drawerClose: {
//     transition: theme.transitions.create('width', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     }),
//     overflowX: 'hidden',
//     width: theme.spacing(9) + 1,
//     [theme.breakpoints.up('sm')]: {
//       width: theme.spacing(9) + 1
//     }
//   },
//   toolbar: {
//     width: '100%',
//     display: 'flex',
//     alignItems: 'center',
//     // justifyContent: 'flex-end',
//     padding: theme.spacing(0, 2),
//     minHeight: 74
//     // necessary for content to be below app bar
//     //...theme.mixins.toolbar
//   },
//   logoWrapper: {},
//   logo: {
//     height: '30px',
//     transition: theme.transitions.create('all', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     })
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3)
//   }
// }));

const Sidebar = ({
  classes,
  sidebarStyle,
  sidebarShadow,
  sidebarFooter,
  sidebarToggle,
  setSidebarToggle,
  windowSize
}) => {
  //   const classes = useStyles();
  //   const theme = useTheme();

  const dispatch = useDispatch();

  const menuOptions = [
    {
      text: 'Dashboard',
      url: '/dashboard',
      icon: <InsertChartOutlined />,
      allowed: userPermission.dashboard.read
    },
    {
      text: 'Pending Users',
      url: '/pending-users',
      icon: <HourglassBottomTwoToneIcon />,
      allowed: userPermission.pending_users.read
    },
    {
      text: 'User Metrics',
      url: '/user-metrics',
      icon: <QueryStatsTwoToneIcon />,
      allowed: userPermission.user_metrics.read
    },

    {
      text: 'Shipper',
      url: '/shipper',
      icon: <GroupsTwoToneIcon />,
      allowed: userPermission.shipper.read
    },
    {
      text: 'Forwarder',
      url: '/forwarder',
      icon: <LocalShippingTwoToneIcon />,
      allowed: userPermission.forwarder.read
    },
    {
      text: 'Shipments',
      url: '/shipments',
      icon: <DirectionsBoatOutlined />,
      allowed: userPermission.shipments.read
    },
    {
      text: 'Articles',
      url: '/articles',
      icon: <RssFeedIcon />,
      allowed: userPermission.articles.read
    },
    {
      text: 'Careers',
      url: '/career',
      icon: <SchoolIcon />,
      allowed: userPermission.career.read
    },
    {
      text: 'Document',
      url: '/document',
      icon: <AttachFile />,
      allowed: userPermission.document.read
    },
    {
      text: 'Subscribers',
      url: '/subscriber-management',
      icon: <MarkEmailReadIcon />,
      allowed: userPermission.subscribers.read
    },
    {
      text: 'Leads Mgmt',
      url: '/shipment-lead-mgmt',
      icon: <DirectionsBoatOutlined />,
      allowed: userPermission.lead.read
    },
    {
      text: 'Team',
      url: '/team',
      icon: <GroupsTwoToneIcon />,
      allowed: userPermission.team.read
    },
    {
      text: 'Tickets',
      url: '/my-tickets',
      icon: <AssignmentIcon />,
      allowed: []
    },
    {
      text: 'Billing',
      url: '/billing',
      icon: <ReceiptOutlined />,
      allowed: userPermission.billing.read
    },
    // {
    //   text: 'Inbox',
    //   url: screenWidth >= 1000 ? '/mailbox' : '/mobi-chat', //this will open mailbox on laptop screens and chat on tablet and below
    //   icon: <MailOutlined />
    // },
    {
      text: 'Settings',
      url: '/settings',
      icon: <SettingsOutlined />,
      allowed: []
    }
  ];

  const auth = useSelector((state) => state.session.auth);

  const deleteReduxData = (name) => {
    if (name === 'Shipper' || name === 'Forwarder') {
      dispatch(setFilterDataInRedux(null));
    }
  };

  const [settingMenu, setSettingMenu] = useState(false);
  const toggleSidebarMobile = () => setSidebarToggle(!sidebarToggle);

  // when screen <= 767px and drawer is open make the body overflow hidden
  useEffect(() => {
    if (screenWidth <= 767 && sidebarToggle) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [sidebarToggle]);

  return (
    <>
      {windowSize >= 767 ? (
        <div
          className={clsx('app-sidebar d-flex', sidebarStyle, {
            'app-sidebar--shadow': sidebarShadow
          })}>
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: sidebarToggle,
              [classes.drawerClose]: !sidebarToggle
            })}
            classes={{
              paper: clsx(classes.drawerPaper, {
                [classes.drawerOpen]: sidebarToggle,
                [classes.drawerClose]: !sidebarToggle
              })
            }}>
            <div className={classes.toolbar}>
              <div className={classes.logoWrapper}>
                <img
                  src={sidebarToggle ? Logo : LogoIcon}
                  alt="Kargoplex logo"
                  className={classes.logo}
                />
              </div>
            </div>
            <Divider className="bg-white-50 opacity-5" />

            <div className="sidebar-navigation">
              <List className="py-3">
                {menuOptions.map((menu, index) => {
                  const key = 'nav_' + index;
                  return (
                    <Fragment key={key}>
                      {menu.url === '/billing' && (
                        <div className="divider opacity-3 mx-2 my-3" />
                      )}
                      {(menu.allowed.includes(auth.accountCmpyLinkType) ||
                        menu.allowed.length === 0) && (
                        <NavLink
                          activeClassName="active"
                          // onClick={toggleSidebarMobile}
                          onClick={() => deleteReduxData(menu.text)}
                          className="nav-link-simple"
                          to={menu.url}>
                          <ListItem button key={menu.text}>
                            <ListItemIcon className="sidebar-icon">
                              <Tooltip
                                arrow
                                classes={{ tooltip: 'tooltip-kargo-blue' }}
                                placement="right"
                                title={!sidebarToggle ? menu.text : ''}>
                                {menu.url === '/mailbox' ? (
                                  <Badge
                                    max={999}
                                    color="error"
                                    badgeContent={23}
                                    invisible={sidebarToggle}>
                                    {menu.icon}
                                  </Badge>
                                ) : (
                                  menu.icon
                                )}
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText>
                              <div className="d-flex align-items-center w-100">
                                {menu.text}
                                {menu.url === '/mailbox' && (
                                  <div className="badge rounded badge-danger ml-auto">
                                    23
                                  </div>
                                )}
                              </div>
                            </ListItemText>
                          </ListItem>
                        </NavLink>
                      )}
                    </Fragment>
                  );
                })}
              </List>
            </div>
          </Drawer>
        </div>
      ) : (
        <div
          className={clsx('app-sidebar d-flex', sidebarStyle, {
            'app-sidebar--shadow': sidebarShadow
          })}
          style={{ zIndex: '20' }}>
          <div
            className={`${Style.container} ${
              !sidebarToggle && Style.collapse
            }`}>
            <div className={Style.main}>
              <div className={`${Style.close_icon_container}`}>
                <img src={Logo} alt="KargoPlex" className={Style.drawer_logo} />

                <IconButton
                  size="small"
                  className={`text-white icon-btn`}
                  onClick={toggleSidebarMobile}>
                  <Close />
                </IconButton>
              </div>

              <div className="sidebar-navigation">
                <List className="py-3">
                  {menuOptions.map((menu, index) => {
                    const key = 'nav_' + index;
                    if (
                      menu.allowed.includes(auth.accountCmpyLinkType) ||
                      menu.allowed.length === 0
                    ) {
                      return (
                        <Fragment key={key}>
                          {menu.url === '/billing' && (
                            <div className="divider opacity-3 mx-2 my-3" />
                          )}

                          {menu.url !== '/settings' ? (
                            <NavLink
                              activeClassName="active"
                              onClick={toggleSidebarMobile}
                              className="nav-link-simple"
                              to={menu.url}>
                              <ListItem button key={menu.text}>
                                <ListItemIcon className="sidebar-icon">
                                  <Tooltip
                                    arrow
                                    classes={{ tooltip: 'tooltip-kargo-blue' }}
                                    placement="right"
                                    title={!sidebarToggle ? menu.text : ''}>
                                    {menu.url === '/mailbox' ? (
                                      <Badge
                                        max={999}
                                        color="error"
                                        badgeContent={23}
                                        invisible={sidebarToggle}>
                                        {menu.icon}
                                      </Badge>
                                    ) : (
                                      menu.icon
                                    )}
                                  </Tooltip>
                                </ListItemIcon>
                                <ListItemText>
                                  <div className="d-flex align-items-center w-100">
                                    {menu.text}
                                    {menu.url === '/mailbox' && (
                                      <div className="badge rounded badge-danger ml-auto">
                                        23
                                      </div>
                                    )}
                                  </div>
                                </ListItemText>
                              </ListItem>
                            </NavLink>
                          ) : (
                            <>
                              <a
                                className={`${Style.settings_container}`}
                                onClick={() =>
                                  setSettingMenu((state) => !state)
                                }>
                                <ListItem button key={menu.text}>
                                  <ListItemIcon className="sidebar-icon text-gray">
                                    <Tooltip
                                      arrow
                                      classes={{
                                        tooltip: 'tooltip-kargo-blue'
                                      }}
                                      placement="right"
                                      title={!sidebarToggle ? menu.text : ''}>
                                      {menu.icon}
                                    </Tooltip>
                                  </ListItemIcon>
                                  <ListItemText>
                                    <div className="d-flex align-items-center justify-content-between pr-2 w-100">
                                      {menu.text}
                                      <ArrowRightIcon />
                                    </div>
                                  </ListItemText>
                                </ListItem>
                              </a>
                              {settingMenu && (
                                <div className={Style.setting_list}>
                                  {SettingsSidebarMenus.map(
                                    (settingMenu, index) => {
                                      const key = 'settingsnav_' + index;
                                      return (
                                        <NavLink
                                          className={`${Style.settingmenu_link} nav-link-simple`}
                                          key={key}
                                          activeClassName={'active'}
                                          to={settingMenu.url}
                                          onClick={toggleSidebarMobile}>
                                          <span>{settingMenu.text}</span>
                                        </NavLink>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </List>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withStyles(styles)(Sidebar);
